import React, { useContext, useState } from "react";

import { Stack } from "@mui/material";

import EventUserContext from "@event/EventUserContext";
import { renderButton, renderCancelButton } from "@shared/FormUtils";
import { formatTime, formatTimeFriendly } from "@shared/TimeUtils";
import { isDeveloper } from "@shared/UserUtils";

const PeopleEventParticipantsView = (props) => {
  const { metadataFields, participant, regFields, resetModal } = props;

  const [showHistory, setShowHistory] = useState(false);
  const { user } = useContext(EventUserContext);

  const renderField = (label, value) => {
    return (
      <div key={label} className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const renderDeveloper = () => {
    if (isDeveloper(user) && participant.gid) {
      return (
        <div className="sg-mgmt-modal-view-section">
          <h2>Developer Details</h2>
          {renderField("Individual GID", participant.individual.gid)}
          {renderField("Participant GID", participant.gid)}
          <div className="py-2" />
          <h2>Data Block</h2>
          {Object.keys(participant.data).map((item) => renderField(item, participant.data[item]))}
        </div>
      );
    }
    return <></>;
  };

  const renderTags = () => {
    return (
      <div>
        <div className="my-4 flex">
          {participant.tags.map((tag) => (
            <div className="mr-2 flex-initial bg-sg-orange bg-opacity-70 px-2 py-1 text-white" key={`tag-${tag.gid}`}>
              {tag.name}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderPhoto = () => {
    if (participant.photo_url) {
      return (
        <div className="sg-mgmt-modal-view-section">
          <h2>Photo</h2>
          <img
            className="sg-mgmt-form-speaker-photo-container cursor-pointer"
            src={participant.photo_url}
            alt="Event Participant"
          />
        </div>
      );
    }
    return <></>;
  };

  const renderSpeakerDetails = () => {
    if (participant.role === "speaker") {
      return (
        <div className="sg-mgmt-modal-view-section">
          <h2>Speaker Details</h2>
          {renderField("Bio", participant.bio)}
        </div>
      );
    }
    return <></>;
  };

  const renderEventFields = () => {
    const fields = [];
    regFields.forEach((f) => {
      const answer = participant.registration_answers[f.slug];
      const answerValue = String(answer || "(none)");
      fields.push(renderField(f.label, answerValue));
    });
    return fields;
  };

  const renderTickets = () => {
    let tickets = {};
    participant.ticketing_participant_tickets.forEach((participant_ticket) => {
      if (tickets[participant_ticket.ticket_type.name] === undefined) {
        tickets[participant_ticket.ticket_type.name] = 1;
      } else {
        tickets[participant_ticket.ticket_type.name] = tickets[participant_ticket.ticket_type.name] + 1;
      }
    });
    return Object.entries(tickets)
      .map(([k, v]) => `${k} (${v})`)
      .join(", ");
  };

  const renderMetadata = () => {
    const fields = [];
    metadataFields.forEach((f) => {
      const answer = participant.metadata.find((md) => md.field_slug == f.slug)?.value;
      const answerValue = String(answer || "(none)");
      fields.push(renderField(f.label, answerValue));
    });
    return fields;
  };

  const renderBool = (bool) => {
    return bool ? "true" : "false";
  };

  const renderTime = (timestamp) => {
    if (!timestamp || !participant || !participant.event) {
      return "";
    }
    return formatTime(timestamp, participant.event.time_zone);
  };

  const renderHistoryButton = () => {
    if (!showHistory) {
      return renderButton("History", () => {
        setShowHistory(true);
      });
    }
    return renderButton("Details", () => {
      setShowHistory(false);
    });
  };

  const renderHistoryEntries = (items) => {
    const sorted = items; // TODO: Actual sort
    return sorted.map((i) => renderHistoryEntry(i));
  };

  const renderHistoryEntry = (item) => {
    let entry = item.change_description;

    if (item.change_value_new !== null) {
      entry = `${entry}: ${item.change_value_new}`;
    }

    if (item.change_value_old !== null && item.change_value_old !== "") {
      entry = `${entry} (was: ${item.change_value_old})`;
    }

    if (item.change_type.match(/ticket/)) {
      entry = item.change_description;
    }

    return (
      <div key={item.gid} className="mb-2">
        <div>{entry}</div>
        <div className="italic">
          {formatTimeFriendly(item.created_at, participant.event.time_zone)} by {item.change_by}
        </div>
      </div>
    );
  };

  const renderParticipantHistory = () => {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{`Participant: ${participant.name_first} ${participant.name_last}`}</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <div className="flex">
              <div className="w-full">
                <div className="sg-mgmt-modal-view-section">
                  <h2>History</h2>
                  {renderHistoryEntries(participant.participant_changes)}
                </div>
                <Stack direction="row" spacing={2}>
                  {renderCancelButton("Done", resetModal)}
                  {renderHistoryButton()}
                </Stack>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStatusTimestamps = () => {
    const fields = [];

    if (participant.registered_at) {
      fields.push(renderField("Registered at", renderTime(participant.registered_at)));
    }

    // show First Registered At only if not currently registered, OR if registered timestamp is different
    // from first registered timestamp
    if (
      participant.first_registered_at &&
      participant.first_registered_at?.split(".")[0] != participant.registered_at?.split(".")[0]
    ) {
      fields.push(renderField("First Registered at", renderTime(participant.first_registered_at)));
    }

    if (participant.declined_at) {
      fields.push(renderField("Declined at", renderTime(participant.declined_at)));
    }

    if (participant.disallowed_at) {
      fields.push(renderField("Disallowed at", renderTime(participant.disallowed_at)));
    }

    if (participant.cancelled_at) {
      fields.push(renderField("Cancelled at", renderTime(participant.cancelled_at)));
    }

    return fields;
  };

  const renderParticipantDetails = () => {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{`Participant: ${participant.name_first} ${participant.name_last}`}</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <div className="flex">
              <div className="w-1/2 pr-4">
                <div className="sg-mgmt-modal-view-section">
                  <h2>Personal Details</h2>
                  {renderField("First Name", participant.name_first)}
                  {renderField("Last Name", participant.name_last)}
                  {renderField("Company", participant.company)}
                  {renderField("Job Title", participant.job_title)}
                </div>
                <div className="sg-mgmt-modal-view-section">
                  <h2>Contact Information</h2>
                  {renderField("Address (line 1)", participant.work_address_street_1)}
                  {renderField("Address (line 2)", participant.work_address_street_2)}
                  {renderField("City", participant.work_address_city)}
                  {renderField("State/Province", participant.work_address_state)}
                  {renderField("ZIP/Postal Code", participant.work_address_postcode)}
                  {renderField("Country", participant.work_address_country)}
                  {renderField("Email", participant.email)}
                  {renderField("Phone", participant.work_phone)}
                </div>
                {renderSpeakerDetails()}
                {renderDeveloper()}
              </div>
              <div className="w-1/2">
                <div className="sg-mgmt-modal-view-section">
                  <h2>Status</h2>
                  {renderField("Role", participant.role)}
                  {renderField("Status", participant.status)}
                  {renderField("Attended", renderBool(participant.attended))}
                  {renderField("Test Flag", renderBool(participant.test_flag))}
                </div>
                <div className="sg-mgmt-modal-view-section">
                  <h2>Timestamps</h2>
                  {renderField("Created at", renderTime(participant.created_at))}
                  {renderField("Updated at", renderTime(participant.updated_at))}
                  {renderStatusTimestamps()}
                </div>
                <div className="sg-mgmt-modal-view-section">
                  <h2>Registration Answers</h2>
                  {renderEventFields()}
                </div>
                <div className="sg-mgmt-modal-view-section">
                  <h2>Tickets</h2>
                  {renderTickets()}
                </div>
                <div className="sg-mgmt-modal-view-section">
                  <h2>Custom Fields</h2>
                  {renderMetadata()}
                </div>
                {renderPhoto()}
                <div className="sg-mgmt-modal-view-section">
                  <h2>Tags</h2>
                  {renderTags()}
                </div>
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderCancelButton("Done", resetModal)}
              {renderHistoryButton()}
            </Stack>
          </div>
        </div>
      </div>
    );
  };

  if (showHistory) {
    return renderParticipantHistory();
  }
  return renderParticipantDetails();
};

export default PeopleEventParticipantsView;
