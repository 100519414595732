import React from "react";

import RouterTabbedPage from "@shared/RouterTabbedPage";

const EventMetadata = () => {
  return (
    <RouterTabbedPage
      tabs={[
        { label: "Custom Fields", path: "/custom_fields" },
        {
          label: "Reg Questions (Old)",
          path: "/custom_fields/registration"
        }
      ]}
    />
  );
};

export default EventMetadata;
