import { useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchDashboard = async (apiRoot, signal) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/dashboard"),
    signal: signal
  });
};

export const useDashboard = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["dashboard", eventId],
    queryFn: ({ signal }) => fetchDashboard(apiRoot, signal),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useRefreshDashboard = (eventId) => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({ queryKey: ["dashboard", eventId] });
  };
};
