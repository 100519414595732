import React from "react";

import { Outlet } from "react-router";

import ContentFrame from "@shared/ContentFrame";
import ErrorFallback from "@shared/ErrorFallback";
import RouterTabs from "@shared/tabs/RouterTabs";
import { useAppsignal } from "@shared/useAppsignal";

const RouterTabbedPage = (props) => {
  const { tabs } = props;
  const { appsignal, ErrorBoundary } = useAppsignal();

  const renderTabs = () => {
    return (
      <RouterTabs
        tabs={tabs.map((tab, index) => {
          return {
            id: index,
            name: tab.label,
            path: tab.path,
            icon: tab.icon
          };
        })}
      />
    );
  };

  return (
    <div className="w-full max-w-full overflow-hidden">
      {renderTabs()}
      <ContentFrame>
        <ErrorBoundary instance={appsignal} fallback={(_error) => <ErrorFallback />}>
          <Outlet />
        </ErrorBoundary>
      </ContentFrame>
    </div>
  );
};

export default RouterTabbedPage;
