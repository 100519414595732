import React from "react";

import RouterTabbedPage from "@shared/RouterTabbedPage";

const Reporting = () => {
  return (
    <RouterTabbedPage
      tabs={[
        { label: "Standard Reports", path: "/reporting" },
        { label: "Custom Reports", path: "/reporting/custom" }
      ]}
    />
  );
};

export default Reporting;
