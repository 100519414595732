import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchTicketPackageType = async (apiRoot, id) => {
  return request({
    method: "GET",
    url: urljoin(apiRoot, `/ticketing/package_types/${id}`)
  });
};

const fetchTicketPackageTypes = async (apiRoot) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/ticketing/package_types")
  });
};

const addTicketPackageType = async (apiRoot, data) => {
  return request({
    method: "POST",
    url: urljoin(apiRoot, `/ticketing/package_types`),
    data: data
  });
};

const updateTicketPackageType = async (apiRoot, id, data) => {
  return request({
    method: "PATCH",
    url: urljoin(apiRoot, `/ticketing/package_types/${id}`),
    data: data
  });
};

const deleteTicketPackageType = async (apiRoot, id) => {
  return request({
    method: "DELETE",
    url: urljoin(apiRoot, `/ticketing/package_types/${id}`)
  });
};

export const useTicketPackageType = (apiRoot, eventId, id) => {
  return useQuery({
    queryKey: ["ticketPackageTypes", eventId, id],
    queryFn: () => fetchTicketPackageType(apiRoot, id),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useTicketPackageTypes = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["ticketPackageTypes", eventId],
    queryFn: () => fetchTicketPackageTypes(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useAddTicketPackageType = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data }) => addTicketPackageType(apiRoot, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["ticketPackageTypes", eventId]);
    }
  });
};

export const useDeleteTicketPackageType = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => deleteTicketPackageType(apiRoot, id),
    onSuccess: () => {
      queryClient.invalidateQueries(["ticketPackageTypes", eventId]);
    }
  });
};

export const useUpdateTicketPackageType = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, data }) => updateTicketPackageType(apiRoot, id, data),
    onSuccess: () => {
      queryClient.invalidateQueries(["ticketPackageTypes", eventId]);
    }
  });
};
