import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import { useTicketPackageType } from "@shared/hooks/useTicketPackages";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { formatTimeUtcToZone } from "@shared/TimeUtils";

const TicketingPackageTypeHistory = (props) => {
  const { packageTypeId } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const packageType = useTicketPackageType(apiRoot, event.id, packageTypeId);

  const renderHistory = () => {
    const sorted = packageType.data.package_type.ticket_package_changes; // TODO: Actual sort

    if (sorted.length === 0) {
      return <div>No history has been recorded for this ticket.</div>;
    }

    return sorted.map((i) => renderHistoryEntry(i));
  };

  const renderHistoryEntry = (item) => {
    const entry = item.change_description;

    return (
      <div key={item.gid} className="mb-2">
        <div>{entry}</div>
        <div className="italic">
          {formatTimeUtcToZone(item.created_at, event.time_zone)} by {item.change_by}
        </div>
      </div>
    );
  };

  if (packageType.isLoading) {
    return <Loading />;
  }

  if (packageType.isError) {
    return <div>Error: {packageType.error}</div>;
  }

  return (
    <div>
      <div className="mb-2">
        <PageHeader text={packageType.data.package_type.name} />
      </div>
      <div className="min-h-80">{renderHistory()}</div>
    </div>
  );
};

export default TicketingPackageTypeHistory;
