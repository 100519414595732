import React, { useContext } from "react";

import Modal from "react-modal";

import EventContext from "@event/EventContext";
import { useParticipant } from "@shared/hooks/useParticipants";
import Loading from "@shared/Loading";

import PeopleEventParticipantsView from "./PeopleEventParticipantsView";

const PeopleEventParticipantsViewModal = (props) => {
  const { participantId, modalVisible, metadataFields, regFields, resetModal } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const participantQuery = useParticipant(apiRoot, event.id, participantId);

  Modal.setAppElement("#root");

  if (participantQuery.isPending) {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Participant:</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <Loading />
          </div>
        </div>
      </div>
    );
  }

  if (participantQuery.isError) {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Participant:</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <div>Error loading participant: {participantQuery.error}</div>;
          </div>
        </div>
      </div>
    );
  }

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="View Participant"
    >
      <PeopleEventParticipantsView
        metadataFields={metadataFields}
        participant={participantQuery.data.participant}
        regFields={regFields}
        resetModal={resetModal}
      />
    </Modal>
  );
};

export default PeopleEventParticipantsViewModal;
