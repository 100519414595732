import React from "react";

import clsx from "clsx";
import { NavLink } from "react-router";

const RouterTabs = (props) => {
  const { tabs, classNames } = props;

  const renderTabs = () => {
    return tabs.map((tab) => renderTab(tab));
  };

  const renderTab = (tab) => {
    return (
      <NavLink
        key={tab.id}
        end
        to={tab.path}
        className={({ isActive }) =>
          clsx(
            "relative mr-1 inline-block h-10 min-w-[174px] cursor-pointer rounded-tl-[3px] rounded-tr-[3px]",
            isActive ? "bg-white" : "bg-[#E1F5FE]"
          )
        }
      >
        {({ isActive }) => (
          <div className={clsx("p-2 text-center text-base text-ui-6", isActive ? "font-medium" : "font-normal")}>
            {tab.icon ? renderTabIcon(tab) : ""}
            {tab.name}
          </div>
        )}
      </NavLink>
    );
  };

  const renderTabIcon = (tab) => {
    return <img className="w-18 h-18 -mt-1 mr-1 inline-block" src={tab.icon} alt={`${tab.name} tab icon`} />;
  };

  return (
    <div
      className={clsx(
        "no-scrollbar h-14 min-w-full overflow-scroll whitespace-nowrap bg-sky-200 pt-4",
        (classNames || {}).outerContainer
      )}
    >
      <div className={`mx-12 ${(classNames || {}).innerContainer}`}>{renderTabs()}</div>
    </div>
  );
};

export default RouterTabs;
