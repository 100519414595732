import React from "react";

import { useParams, useNavigate } from "react-router";

import ContentFrame from "@shared/ContentFrame";

import FormsFormsForm from "./forms/FormsFormsForm";

const FormsEdit = () => {
  const navigate = useNavigate();
  const { id, form_type } = useParams();

  return (
    <ContentFrame>
      <FormsFormsForm
        formId={id != "new" ? id : null}
        cancel={() => navigate(`/forms/${form_type}`)}
        form_type={form_type}
      />
    </ContentFrame>
  );
};

export default FormsEdit;
