import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Stack } from "@mui/material";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderButton, renderTextField } from "@shared/FormUtils";

const PeopleEventParticipantsTagsAdd = (props) => {
  const { apiRoot } = useContext(EventContext).values;
  const { update } = props;

  const renderAddButton = (isSubmitting, textboxFilled) => {
    return renderButton("Add", () => {}, {
      type: "submit",
      color: "secondary",
      disabled: textboxFilled || isSubmitting
    });
  };

  return (
    <div>
      <Formik
        initialValues={{
          tag: {
            name: ""
          }
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const form = document.getElementById("sg-mgmt-participant-tags-form");
          const formData = new FormData(form);

          // ignore submitting the placeholder text
          if (formData.get("tag[name]").length === 0) {
            setSubmitting(false);
            return;
          }

          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
          axios({
            url: urljoin(apiRoot, "/participant_tags"),
            method: "POST",
            data: formData
          })
            .then((response) => {
              if (response.data.error === null) {
                update(response.data.tag);
                alertSuccess("Tag created");
                setSubmitting(false);
                resetForm();
              } else {
                alertError(response.data.error);
                setSubmitting(false);
              }
            })
            .catch((error) => {
              alertHttpError(error);
            });
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className="sg-mgmt-form" id="sg-mgmt-participant-tags-form">
            <Stack direction="row" spacing={2}>
              {renderTextField("Name", "tag[name]")}
              <div className="sg-mgmt-form-input-container">
                <label className="mb-[0.5px]"> &nbsp;</label>
                {renderAddButton(isSubmitting, !values.tag.name)}
              </div>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};

PeopleEventParticipantsTagsAdd.propTypes = {
  update: PropTypes.func.isRequired
};

export default PeopleEventParticipantsTagsAdd;
