import React from "react";
import PropTypes from "prop-types";

import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import MuiSelectClearable from "@shared/MuiSelectClearable";
const WidgetFilterByTag = props => {
  const { control, tags } = props;
  return (
    <div className="mb-4 mt-4">
      <div className="mb-4 w-full">
        <FormControl fullWidth>
          <FormLabel>Filter by Tag (optional)</FormLabel>
          <Controller
            name="widget_config[filters][tag]"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <Select
                {...field}
                fullWidth
                onChange={onChange}
                value={value}
                variant="outlined"
                size="small"
                MenuProps={{ disableScrollLock: true }}
                endAdornment={(
                  <MuiSelectClearable value={value} onChange={onChange} />
                )}
              >
                <MenuItem value={""}>(none)</MenuItem>
                {tags.map(tag => (
                  <MenuItem key={`tag-${tag.id}`} value={tag.id}>
                    {tag.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};

WidgetFilterByTag.propTypes = {
  control: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired
};

export default WidgetFilterByTag;
