import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import { formatDuration, intervalToDuration, isBefore, isSameDay, parseISO } from "date-fns";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertHttpError } from "@shared/Alerts";
import ContentFrame from "@shared/ContentFrame";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

const DashboardLegacy = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [overview, setOverview] = useState(null);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchOverview = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "overview"));
        setOverview(result.data);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchOverview();
  }, [apiRoot]);

  const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

  const renderCountdown = () => {
    if (!event.date_begin || !event.date_end) {
      return "Set start and end dates in event settings";
    }

    const now = new Date();
    const eventStart = parseISO(event.date_begin);
    const eventEnd = parseISO(event.date_end);

    if (isBefore(now, eventStart)) {
      const duration = formatDuration(
        intervalToDuration({
          start: now,
          end: eventStart
        }),
        {
          format: ["months", "days"],
          delimiter: ", "
        }
      );
      return `Event begins in ${duration}`;
    }
    if (isSameDay(now, eventStart)) {
      return "Event starts today!";
    }
    if (isBefore(now, eventEnd)) {
      return "Event in progress!";
    }
    return "Event ended.";
  };

  const renderField = (label, value) => {
    return (
      <div className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const renderOverview = () => {
    if (fetched) {
      return (
        <div className="flex">
          <div className="flex-1">
            <h2>Quick Stats</h2>
            {renderField("Attendee Count", overview.attendee_count)}
            {renderField("(registered)", overview.attendee_registered_count)}
            {renderField("(preregistered)", overview.attendee_preregistered_count)}
            {renderField("(declined)", overview.attendee_declined_count)}
            <div className="py-3" />
            {renderField("Speaker Count", overview.speaker_count)}
            {renderField("Session Count", overview.session_count)}
            <div className="py-3" />
            <h2>Event Details</h2>
            {renderField("Site URL", event.site_url)}
            {renderField("Event Type", capitalize(event.event_type))}
          </div>
          <div className="flex-1">
            <h2>Countdown to Event</h2>
            {renderCountdown()}
            <div className="py-3" />
            <h2>Current User</h2>
            {renderField("Name", `${user.name_first} ${user.name_last}`)}
            {renderField("Email", user.email)}
            {renderField("Current sign-in IP", user.current_ip)}
          </div>
        </div>
      );
    }
    return <Loading />;
  };

  return (
    <ContentFrame>
      <PageHeader text="Overview" />
      {renderOverview()}
    </ContentFrame>
  );
};

export default DashboardLegacy;
