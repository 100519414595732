import React, { useState } from "react";

import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LicenseInfo } from "@mui/x-license-pro";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfirmProvider } from "material-ui-confirm";
import { SnackbarProvider } from "notistack";
import { createBrowserRouter } from "react-router";
import { RouterProvider } from "react-router/dom";

import { ROUTES } from "@routes/eventRoutes";
import ErrorFallback from "@shared/ErrorFallback";
import MaterialTheme from "@shared/MaterialTheme";
import MuiAlert from "@shared/MuiAlert";
import { useAppsignal } from "@shared/useAppsignal";

import EventContext from "./EventContext";
import EventHeartbeat from "./EventHeartbeat";
import EventUserContext from "./EventUserContext";

// ref: https://deploy-preview-31991--material-ui.netlify.app/material-ui/react-snackbar/#experimental-api
// replace notistack with labs provider once it's available
// import { SnackbarsProvider } from '@mui/lab';

const MUI_KEY =
  "d17c34eea831104e9aaa6a1fcb440234Tz04NDAxMixFPTE3MzkxNDQzODUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y";
LicenseInfo.setLicenseKey(MUI_KEY);

const EventApp = (props) => {
  const { apiRoot, event, rootUrl, urlBase, user, webRoot } = props;
  const { appsignal, ErrorBoundary } = useAppsignal();
  const queryClient = new QueryClient();
  const router = createBrowserRouter(ROUTES, {
    basename: urlBase
  });

  const eventContextPropsValue = {
    apiRoot,
    event,
    rootUrl,
    urlBase,
    webRoot
  };

  const [eventContextValue, setEventContextValue] = useState(eventContextPropsValue);

  const eventContext = {
    values: eventContextValue,
    update: setEventContextValue
  };

  const eventUserContextValue = {
    user
  };

  // React Query Devtools aren't supposed to show up if process.NODE_ENV != "development",
  // but this doesn't seem to apply to a client-side build? So we check our the NODE_ENV
  // that we export from esbuild
  const renderReactQueryDevTools = () => {
    if (ESB_NODE_ENV !== "development") {
      return <></>;
    }
    return <ReactQueryDevtools initialIsOpen={false} />;
  };

  return (
    <ErrorBoundary instance={appsignal} tags={{ tag: "EventApp" }} fallback={(_error) => <ErrorFallback />}>
      <EventHeartbeat apiRoot={apiRoot} />
      <EventContext.Provider value={eventContext}>
        <EventUserContext.Provider value={eventUserContextValue}>
          <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <ThemeProvider theme={MaterialTheme}>
                <SnackbarProvider
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  autoHideDuration={3500}
                  Components={{
                    mui: MuiAlert
                  }}
                >
                  <ConfirmProvider>
                    <RouterProvider router={router} />
                  </ConfirmProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </LocalizationProvider>
            {renderReactQueryDevTools()}
          </QueryClientProvider>
        </EventUserContext.Provider>
      </EventContext.Provider>
    </ErrorBoundary>
  );
};

export default EventApp;
