import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import { useTicketType, useTicketTags } from "@shared/hooks/useTickets";
import Loading from "@shared/Loading";

import TicketingTicketTypeForm from "./TicketingTicketTypeForm";

const TicketingTicketTypeEdit = (props) => {
  const { cancelButton, callbackFailure, callbackSuccess, ticketTypeId } = props;
  const { apiRoot, event } = useContext(EventContext).values;

  const ticketType = useTicketType(apiRoot, event.id, ticketTypeId);
  const ticketTags = useTicketTags(apiRoot);

  if (ticketType.isLoading || ticketTags.isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (ticketType.isError) {
    return <div>Error: {ticketType.error}</div>;
  }
  if (ticketTags.isError) {
    return <div>Error: {ticketTags.error}</div>;
  }

  return (
    <TicketingTicketTypeForm
      cancel={cancelButton}
      callbackSuccess={callbackSuccess}
      callbackFailure={callbackFailure}
      ticketType={ticketType.data.ticket_type}
      ticketTags={ticketTags.data.tags}
    />
  );
};

export default TicketingTicketTypeEdit;
