import React from "react";

import { Autocomplete, TextField } from "@mui/material";
import clsx from "clsx";

const CustomReportFilterField = (props) => {
  const { filter, updateAllCriteria, index, options, operationOptions, deleteIndex } = props;

  const setFilterOption = (key) => {
    updateAllCriteria({
      selectedOption: key,
      operation: filter.operation,
      operationValue: filter.operationValue,
      count: index
    });
  };

  const setFilterOperation = (key) => {
    updateAllCriteria({
      selectedOption: filter.selectedOption,
      operation: key,
      operationValue: filter.operationValue,
      count: index
    });
  };

  const setFilterValue = (key) => {
    updateAllCriteria({
      selectedOption: filter.selectedOption,
      operation: filter.operation,
      operationValue: key.target.value,
      count: index
    });
  };

  const renderSelect = (options, value, onChange, name) => {
    return (
      <>
        <Autocomplete
          options={options}
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField autoComplete="off" {...params} />}
          disablePortal
          sx={{
            width: "200px"
          }}
        />
        <input type="hidden" name={name} value={value} />
      </>
    );
  };

  const renderValueField = (operation, value) => {
    let disabled = false;

    // fields which do not take a user-input value
    const operationsWithNoInputVal = ["is_true", "is_false", "is_empty", "is_not_empty"]
    if (operationsWithNoInputVal.includes(operation?.value)) {
      disabled = true;
    }

    const fieldValue = disabled ? "" : value;
    const fieldPlaceholder = disabled ? "" : "..value..";

    return (
      <input
        type="text"
        className={clsx("report-tool-filter-input", {
          "report-tool-filter-input-disabled": disabled
        })}
        style={{ marginBottom: "0px" }}
        placeholder={fieldPlaceholder}
        disabled={disabled}
        value={fieldValue}
        onChange={(key) => {
          setFilterValue(key);
        }}
      />
    );
  };

  return (
    <>
      {renderSelect(options, filter.selectedOption, (_e, newValue) => {
        setFilterOption(newValue);
      })}
      {renderSelect(operationOptions, filter.operation, (_e, newValue) => {
        setFilterOperation(newValue);
      })}
      {renderValueField(filter.operation, filter.operationValue)}
      <div
        className="report-tool-delete-modifier"
        onClick={() => {
          deleteIndex(index);
        }}
      >
        <img src="/images/reporting/form-icons/icon-close-grey.svg" />
      </div>
    </>
  );
};

export default CustomReportFilterField;
