import React from "react";

import PageHeader from "@shared/PageHeader";

import TicketingTicketTags from "./TicketingTicketTags";

const TicketingTicketEditSettings = () => {
  return (
    <div className="sg-mgmt-content sg-mgmt-content-full max-w-[700px]">
      <PageHeader text="Ticket Settings" />
      <div className="mb-6 mt-12">
        <TicketingTicketTags />
      </div>
    </div>
  );
};

export default TicketingTicketEditSettings;
