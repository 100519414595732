import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { useAppsignal } from "@shared/useAppsignal";
import { isPrivileged } from "@shared/UserUtils";

import Dashboard from "./Dashboard";
import DashboardLegacy from "./DashboardLegacy";

const DashboardWrapper = () => {
  const { event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const { appsignal, ErrorBoundary } = useAppsignal();
  const dashboardEnabled = !(event?.features?.find((f) => f.slug === "olddashboard")?.status === "enabled");
  const dashboardAllowed = isPrivileged(user) || user?.permission?.dashboard_view;
  const showDashboard = dashboardEnabled && dashboardAllowed;

  return (
    <>
      {showDashboard && (
        <ErrorBoundary
          key={`error-boundary-dashboard`}
          instance={appsignal}
          tags={{ tag: "dashboard" }}
          fallback={(_error) => <DashboardLegacy error={_error} />}
        >
          <Dashboard />
        </ErrorBoundary>
      )}
      {!showDashboard && <DashboardLegacy />}
    </>
  );
};

export default DashboardWrapper;
