import React from "react";

import { Outlet } from "react-router";

import PeopleHeartbeat from "./PeopleHeartbeat";
import MainMenu from "../main/MainMenu";

const PeopleApp = (props) => {
  const { rootUrl } = props;

  return (
    <div className="sg-mgmt-content-frame">
      <MainMenu />
      <PeopleHeartbeat rootUrl={rootUrl} />
      <Outlet />
    </div>
  );
};

export default PeopleApp;
