import React from "react";

import { Link } from "react-router";

const MainIndexNewEventTile = () => {
  const linkPath = "/events/new";

  return (
    <div className="sg-mgmt-tile-container">
      <Link to={linkPath}>
        <div className="sg-mgmt-tile bg-neutral-200">
          <img className="sg-mgmt-tile-new-event-plus" src="/images/icons/add-filled.svg" alt="Add New Event" />
          <div className="text-center font-['Roboto'] text-base font-normal leading-normal tracking-tight text-black">
            Add New Event
          </div>
        </div>
        <div className="sg-mgmt-tile-title">&nbsp;</div>
      </Link>
    </div>
  );
};

export default MainIndexNewEventTile;
