import React from "react";

import Modal from "react-modal";

import { alertError } from "@shared/Alerts";

import MetadataCopyEventForm from "./MetadataCopyEventForm";

const MetadataCopyEventModal = (props) => {
  const { modalVisible, title, fieldType, closeModal, callbackSuccess = () => {}, callbackFailure = () => {} } = props;

  Modal.setAppElement("#root");

  const success = (response) => {
    callbackSuccess(response);
  };

  const failure = () => {
    alertError("Failed to save field");
    callbackFailure();
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={closeModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <MetadataCopyEventForm
            callbackFailure={failure}
            callbackSuccess={success}
            cancelButton={closeModal}
            fieldType={fieldType}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MetadataCopyEventModal;
