import React, { useContext } from "react";
import PropTypes from "prop-types";

import { DeleteForever as DeleteForeverIcon } from "@mui/icons-material";
import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";

const WidgetDeleteIcon = (props) => {
  const { id, onUpdate } = props;
  const { apiRoot } = useContext(EventContext).values;
  const confirm = useConfirm();

  const confirmDelete = () => {
    confirm({
      title: "Confirm delete",
      description: "Are you sure you want to delete this widget?"
    })
      .then(() => {
        performDelete();
      })
      .catch((err) => {
        if (err) alertError(err);
      });
  };

  const performDelete = () => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/widgets", `/${id}`),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error === null) {
          onUpdate();
          alertSuccess("Widget deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  return (
    <div className="absolute right-0 top-0 mr-2 mt-2 block cursor-pointer" onClick={confirmDelete}>
      <DeleteForeverIcon />
    </div>
  );
};

WidgetDeleteIcon.propTypes = {
  id: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default WidgetDeleteIcon;
