import React from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const MetadataTableFieldDragContainer = (props) => {
  const { id, children } = props;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <tr {...attributes} ref={setNodeRef} style={style}>
      <td className="relative border px-4 py-2 text-center align-top text-sm">
        <img
          className="m-auto cursor-pointer bg-white text-center"
          src={"/images/icons/drag_dots.svg"}
          {...listeners}
        />
      </td>
      {children}
    </tr>
  );
};

export default MetadataTableFieldDragContainer;
