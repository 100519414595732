import { InputAdornment, IconButton } from "@mui/material";
import React, { useState } from "react";
import { Clear } from '@mui/icons-material';

// >:( 
// https://github.com/mui/material-ui/issues/27141


const MuiSelectClearable = ({ onChange, value }) => {
  if(value){
    return (
      <InputAdornment
        onClick={() => onChange(null)}
        sx={{ marginRight: "10px" }} position="end"
      >
        <IconButton>
          <Clear/>
        </IconButton>
      </InputAdornment>
    )
  }
  return <></>
}

export default MuiSelectClearable