import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import PageHeader from "@shared/PageHeader";

import SessionsVenue from "./SessionsVenue";
import SessionsVenueRooms from "./SessionsVenueRooms";
import SessionsVenuesIndex from "./SessionsVenuesIndex";

const SessionsVenues = (props) => {
  const { forceUpdate } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [page, setPage] = useState("index");
  const [editVenueId, setEditVenueId] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [venues, setVenues] = useState([]);

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/venues"));
        setVenues(result.data.venues);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchVenues();
  }, [apiRoot]);

  const goToPage = (p) => {
    setPage(p);
  };

  useEffect(() => {
    goToPage("index");
  }, [forceUpdate]);

  const sortVenues = (venuesToSort) => sortBy(venuesToSort, ["name"]);

  const addVenue = (venue) => {
    setVenues(sortVenues(venues.concat(venue)));
  };

  const updateVenues = (venue) => {
    const newVenues = venues.map((h) => (h.gid === venue.gid ? venue : h));
    setVenues(newVenues);
  };

  const renderPage = () => {
    switch (page) {
      case "index":
        return (
          <SessionsVenuesIndex
            addVenue={addVenue}
            setEditVenueId={setEditVenueId}
            fetched={fetched}
            venues={venues}
            goEdit={() => goToPage("edit")}
            goRooms={() => goToPage("rooms")}
            updateVenues={updateVenues}
          />
        );
      case "edit":
        return (
          <SessionsVenue
            editVenueId={editVenueId}
            goIndex={() => goToPage("index")}
            setEditVenueId={setEditVenueId}
            updateVenues={updateVenues}
          />
        );
      case "rooms":
        return (
          <SessionsVenueRooms venueId={editVenueId} goIndex={() => goToPage("index")} setEditVenueId={setEditVenueId} />
        );
      default:
        throw new Error("invalid venues page");
    }
  };

  return (
    <div>
      <PageHeader text="Venue Management" />
      <div className="sg-mgmt-content-section">{renderPage()}</div>
    </div>
  );
};

export default SessionsVenues;
