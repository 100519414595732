import React, { useCallback, useEffect, useState, useContext } from "react";

import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import { Link } from "react-router";
import urljoin from "url-join";

import { renderCreateButton } from "@/shared/FormUtils";
import { formatTime } from "@/shared/TimeUtils";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertHttpError, alertSuccess } from "@shared/Alerts";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

const TemplateIndex = () => {
  const confirm = useConfirm();
  const { user } = useContext(EventUserContext);
  const { event, apiRoot } = useContext(EventContext).values;
  const [templates, setTemplates] = useState([]);
  const [fetched, setFetched] = useState(false);

  const columns = [
    {
      field: "name",
      headerName: "Name"
    },
    {
      field: "updated_at",
      headerName: "Last Updated",
      renderCell: (params) => {
        return formatTime(params.value, event.time_zone);
      }
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      minWidth: 150,
      flex: 1,
      getActions: (params) => [renderView(params.row), renderEdit(params.row), renderDelete(params.row)]
    }
  ];

  const renderView = (item) => {
    return (
      <>
        <Link className="cursor-pointer" to={`/content/web/templates/${item.id}/web_pages`}>
          View Web Pages
        </Link>
      </>
    );
  };

  const renderEdit = (item) => {
    if (editEnabled()) {
      return (
        <>
          <Link className="cursor-pointer" to={`/content/web/templates/${item.id}`}>
            Edit
          </Link>
        </>
      );
    }
  };

  const attemptDelete = async (item) => {
    confirm({
      description: `Are you sure you want to delete ${item.name}? This cannot be undone.`,
      confirmationText: "Delete"
    }).then(async () => {
      try {
        const _result = await axios.delete(urljoin(apiRoot, `/contents/web_page_templates/${item.id}`));
        alertSuccess("Template successfully deleted");
        setFetched(false);
        fetchTemplates();
      } catch (error) {
        alertHttpError(error);
      }
    });
  };

  const renderDelete = (item) => {
    if (editEnabled()) {
      return (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              attemptDelete(item);
            }}
          >
            Delete
          </span>
        </>
      );
    }
  };

  const fetchTemplates = useCallback(async () => {
    try {
      const result = await axios(urljoin(apiRoot, "/contents/web_page_templates"));
      setTemplates(result.data.web_page_templates);
      setFetched(true);
    } catch (error) {
      alertHttpError(error);
    }
  }, [apiRoot]);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  const renderAddTemplateButton = () => {
    if (editEnabled()) {
      return <Link to="/content/web/templates/new">{renderCreateButton("Add Template", () => {})}</Link>;
    }
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.static_files_edit) {
      return false;
    }
    return true;
  };

  const renderWebPagesTable = () => {
    if (!fetched) {
      return <Loading />;
    } else {
      return (
        <GrowlTable columns={columns} items={templates} sortColumn="name" sortDirection="asc" tableName="templates" />
      );
    }
  };
  return (
    <div>
      <PageHeader text="Web Page Templates" />
      {renderAddTemplateButton()}
      <br />
      <br />
      {renderWebPagesTable()}
    </div>
  );
};

export default TemplateIndex;
