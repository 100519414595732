import React, { useContext, useEffect, useState } from "react";

import { Stack } from "@mui/material";
import axios from "axios";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";
import { renderCancelButton, renderSubmitButton, renderTextField } from "@shared/FormUtils";
import PageHeader from "@shared/PageHeader";

const CommunicationsEmailSettings = () => {
  const { apiRoot, webRoot } = useContext(EventContext).values;
  const [config, setConfig] = useState([]);
  const [fetched, setFetched] = useState(false);
  const navigate = useNavigate();
  const formId = "sg-mgmt-event-communications-settings-form";

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/communications/settings"));
        setConfig(result.data.config);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchConfig();
  }, [apiRoot]);

  const back = () => {
    navigate("/communications");
  };

  const renderForm = () => (
    <Formik
      initialValues={{
        config: {
          email_domain: config.email_domain || ""
        }
      }}
      onSubmit={(values, { setSubmitting }) => {
        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

        axios({
          url: urljoin(apiRoot, "/communications/settings"),
          method: "PATCH",
          data: values
        }).then((response) => {
          if (response.data.error === null) {
            window.location.replace(urljoin(webRoot, "/communications"));
          } else {
            alertError(response.data.error);
            setSubmitting(false);
          }
        });
      }}
    >
      {({ isSubmitting }) => (
        <Form className="sg-mgmt-form" id={formId}>
          <div className="sg-mgmt-form-container">
            <div className="w-1/2">{renderTextField("Email Domain", "config[email_domain]")}</div>
          </div>
          <Stack direction="row" spacing={2}>
            {renderSubmitButton("Save", isSubmitting)}
            {renderCancelButton("Cancel", back)}
          </Stack>
        </Form>
      )}
    </Formik>
  );

  const renderConfig = () => {
    if (fetched) {
      return <div className="mt-2">{renderForm()}</div>;
    }
    return (
      <div className="mt-2">
        <h2>Loading</h2>
      </div>
    );
  };

  return (
    <div>
      <PageHeader text="Communications Settings" />
      {renderConfig()}
    </div>
  );
};

export default CommunicationsEmailSettings;
