import React, { useState } from "react";

import { renderTextField } from "@shared/FormUtils";

const MetadataFieldOptionsNew = (props) => {
  const { field } = props;
  const blankField = { slug: "", label: "" };
  const startIdx = field.options.length;
  const [options, setOptions] = useState([]);

  const addOption = () => {
    setOptions((prevOptions) => prevOptions.concat([blankField]));
  };

  const renderOptionsField = (idx) => {
    return (
      <div key={idx}>
        <div>{renderTextField("Slug", `field[options][${idx}][slug]`)}</div>
        <div>{renderTextField("Label (question)", `field[options][${idx}][label]`)}</div>
      </div>
    );
  };

  const renderOptionsFields = () => {
    return (
      <div>
        {options.map((_opt, idx) => renderOptionsField(idx + startIdx))}
        <span className="sg-mgmt-link" onClick={addOption}>
          + Add another option
        </span>
      </div>
    );
  };

  return renderOptionsFields();
};

export default MetadataFieldOptionsNew;
