import React from "react";

import RouterTabbedPage from "@shared/RouterTabbedPage";
import { TabSettingsIcon } from "@shared/tabs/TabIcons";

const Housing = () => {
  return (
    <RouterTabbedPage
      tabs={[
        { label: "Overview", path: "/housing" },
        { label: "Hotels", path: "/housing/hotels" },
        { label: "Bookings", path: "/housing/bookings" },
        { label: "Room Blocks", path: "/housing/room_blocks" },
        { label: "Reports", path: "/housing/reports" },
        { label: "Settings", path: "/housing/settings", icon: TabSettingsIcon }
      ]}
    />
  );
};

export default Housing;
