import React, { useContext, useState } from "react";

import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import { useTicketTypes, useDeleteTicketType } from "@shared/hooks/useTickets";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { formatTimeUtcToZone } from "@shared/TimeUtils";

import TicketingTicketTypeFormModal from "./TicketingTicketTypeFormModal";
import TicketingTicketTypeHistoryModal from "./TicketingTicketTypeHistoryModal";
import { ticketTotalCount, ticketTotalAvailable } from "./TicketingUtils";

const TicketingTickets = () => {
  const { user } = useContext(EventUserContext);
  const { apiRoot, event } = useContext(EventContext).values;
  const [ticketTypeModalOpen, setTicketTypeModalOpen] = useState(false);
  const [ticketTypeModalTarget, setTicketTypeModalTarget] = useState(null);
  const [ticketTypeHistoryModalOpen, setTicketTypeHistoryModalOpen] = useState(false);
  const [ticketTypeHistoryModalTarget, setTicketTypeHistoryModalTarget] = useState(null);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const ticketTypes = useTicketTypes(apiRoot, event.id);
  const deleteTicketType = useDeleteTicketType(apiRoot, event.id);

  const renderTags = (params) => {
    return params.row.tags.map((tag) => tag.name).join(", ");
  };

  const columns = [
    {
      headerName: "Ticket Name",
      field: "name",
      flex: 1
    },
    {
      headerName: "Description",
      field: "notes",
      flex: 1
    },
    {
      headerName: "Total Count",
      field: "total_count",
      renderCell: (params) => ticketTotalCount(params.row),
      flex: 1
    },
    {
      headerName: "Assigned",
      field: "total_assigned",
      flex: 1
    },
    {
      headerName: "Available",
      field: "total_available",
      renderCell: (params) => ticketTotalAvailable(params.row, params.row.total_assigned),
      flex: 1
    },
    {
      headerName: "Tags",
      field: "tags",
      renderCell: (params) => renderTags(params),
      flex: 1
    },
    {
      headerName: "Updated At",
      field: "updated_at",
      renderCell: (params) => formatTimeUtcToZone(params.value, event.time_zone),
      flex: 1,
      minWidth: 200
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      flex: 1,
      align: "right",
      minWidth: 320,
      getActions: (params) => [
        renderViewAction(params.row),
        renderHistoryAction(params.row.id),
        renderEditAction(params.row),
        renderDeleteAction(params.row)
      ]
    }
  ];

  const renderViewAction = (ticket) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            viewTicket(ticket);
          }}
        >
          Assignments
        </span>
      </>
    );
  };

  const renderHistoryAction = (id) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            setTicketTypeHistoryModalTarget(id);
            setTicketTypeHistoryModalOpen(true);
          }}
        >
          History
        </span>
      </>
    );
  };

  const renderEditAction = (id) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            openEditTicketTypeModal(id);
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const renderDeleteAction = (item) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            confirm({
              title: "Confirm delete",
              description: "Are you sure you want to delete this ticket?"
            })
              .then(() => {
                performDelete(item);
              })
              .catch((err) => {
                if (err) alertError(err);
              });
          }}
        >
          Delete
        </span>
      </>
    );
  };

  const performDelete = (ticket) => {
    deleteTicketType.mutate(
      { id: ticket.id },
      {
        onSuccess: () => {
          alertSuccess("Ticket type deleted successfully");
        },
        onError: (error) => {
          alertError(error);
        }
      }
    );
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.ticketing_edit) {
      return false;
    }
    return true;
  };

  const openNewTicketTypeModal = () => {
    setTicketTypeModalTarget(null);
    setTicketTypeModalOpen(true);
  };

  const openEditTicketTypeModal = (item) => {
    setTicketTypeModalTarget(item.id);
    setTicketTypeModalOpen(true);
  };

  const closeTicketTypeModal = () => {
    setTicketTypeModalOpen(false);
    setTicketTypeModalTarget(null);
  };

  const viewTicket = (ticketType) => {
    //TODO go to edit page
    navigate(`/ticketing/tickets/${ticketType.id}`);
  };

  if (ticketTypes.isLoading) {
    return <Loading />;
  }

  if (ticketTypes.isError) {
    return <div>Error: {ticketTypes.error}</div>;
  }

  return (
    <>
      <div>
        <PageHeader text="Tickets Overview" />
        <p className="mb-4 w-1/2">
          In the ticketing module you can create tickets or packages, assign them to users, and manage and track those
          assignments inside of a singular event.
        </p>
        {editEnabled() && renderCreateButton("Create New Ticket Type", openNewTicketTypeModal)}
        <div className="mt-4">
          <GrowlTable
            columns={columns}
            items={ticketTypes.data.ticket_types}
            sortField="name"
            sortDirection="asc"
            tableName={`${event.slug}-tickets`}
          />
        </div>
        <TicketingTicketTypeFormModal
          ticketTypeId={ticketTypeModalTarget}
          modalVisible={ticketTypeModalOpen}
          resetModal={closeTicketTypeModal}
          callbackFailure={() => {}}
          callbackSuccess={() => {
            closeTicketTypeModal();
          }}
        />
        <TicketingTicketTypeHistoryModal
          ticketTypeId={ticketTypeHistoryModalTarget}
          modalVisible={ticketTypeHistoryModalOpen}
          resetModal={() => {
            setTicketTypeHistoryModalOpen(false);
            setTicketTypeHistoryModalTarget(null);
          }}
        />
      </div>
    </>
  );
};

export default TicketingTickets;
