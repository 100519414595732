import React, { useContext, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";
import { renderCancelButton, renderSelectField, renderSubmitButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";

const FormCopyForm = ({ cancel, callbackSuccess, formType }) => {
  const [forms, setForms] = useState(null);
  const { apiRoot, event } = useContext(EventContext).values;
  useEffect(() => {
    const fetchForms = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/forms/all_available/"));
        setForms(result.data["forms"].filter((x) => x.form_type == formType));
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchForms();
  }, [apiRoot, formType]);

  const formConfig = (() => {
    return {
      alert: "added",
      formId: "sg-mgmt-form-forms-add",
      method: "POST",
      saveButton: "Copy"
    };
  })();

  const formValidation = (values) => {
    const errors = {};
    if (!values.selected_form_id) {
      alert("You must select a form.");
      errors.feature = errors.feature || {};
      errors.feature.name = "Required";
    }
    return errors;
  };

  const renderFormFields = (_formikProps) => {
    const options = forms
      .filter((form) => form.name && form.event)
      .map((form) => {
        return {
          value: form.id,
          label: form.event.name + ": " + form.name || ""
        };
      })
      .sort((a, b) => {
        return a.label < b.label ? 1 : -1;
      });
    return (
      <div className="sg-mgmt-form-container">
        <div className="sg-mgmt-form-section">
          {renderSelectField("Forms", "selected_form_id", options, "", [], true, {
            placeHolder: "Select a form"
          })}
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          selected_form_id: null
        }}
        validate={formValidation}
        validateOnChange={false}
        validateOnBlur={false}
        validateOnSubmit={true}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
          axios({
            url: urljoin(apiRoot, `/forms/${values.selected_form_id}/clone`),
            method: "POST",
            data: {
              event_gid: event.gid
            }
          })
            .then((response) => {
              if (response.data.error === null) {
                const new_form = response.data.form;
                callbackSuccess(new_form);
                setSubmitting(false);
              } else {
                setSubmitting(false);
                alertError(response.data.error);
              }
            })
            .catch((error) => {
              setSubmitting(false);
              alertHttpError(error);
            });
        }}
      >
        {(formikProps) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            {renderFormFields(formikProps)}
            {renderButtons(formikProps)}
          </Form>
        )}
      </Formik>
    );
  };

  const renderButtons = (formikProps) => {
    const { isSubmitting } = formikProps;
    return (
      <Stack direction="row" spacing={2}>
        {renderSubmitButton(formConfig.saveButton, isSubmitting, {
          color: "secondary"
        })}
        {renderCancelButton("Cancel", cancel, {
          color: "secondary"
        })}
      </Stack>
    );
  };

  return (
    <div>
      <div className="mb-2">
        Don’t start from scratch! Select a form from a previous event, copy it over to your event, and then make your
        updates.
      </div>
      {forms != null ? renderForm() : <Loading />}
    </div>
  );
};

export default FormCopyForm;
