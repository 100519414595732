import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchParticipant = async (apiRoot, participantId, signal) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, `/participants/${participantId}`),
    signal: signal
  });
};

const fetchParticipantsMeta = async (apiRoot, signal) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/participants/meta"),
    signal: signal
  });
};

const fetchParticipantsTable = async (apiRoot) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/participants/table")
  });
};

const deleteParticipant = async (apiRoot, participantId) => {
  return request({
    method: "delete",
    url: urljoin(apiRoot, `/participants/${participantId}`)
  });
};

const addParticipantToCache = (queryClient, eventId, participant) => {
  queryClient.setQueryData(["participantsTable", eventId], (existing) => {
    const newParticipants = [...existing.participants, participant];
    return { participants: newParticipants };
  });
};

const removeParticipantFromCache = (queryClient, eventId, participantId) => {
  queryClient.setQueryData(["participantsTable", eventId], (existing) => {
    const newParticipants = existing.participants.filter((p) => p.id !== participantId);
    return { participants: newParticipants };
  });
};

const updateParticipantInCache = (queryClient, eventId, participant) => {
  queryClient.setQueryData(["participantsTable", eventId], (existing) => {
    const newParticipants = existing.participants.map((p) => (p.gid === participant.gid ? participant : p));
    return { participants: newParticipants };
  });
};

export const useParticipant = (apiRoot, eventId, participantId) => {
  return useQuery({
    queryKey: ["participants", eventId, participantId],
    queryFn: ({ signal }) => fetchParticipant(apiRoot, participantId, signal),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useParticipantsMeta = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["participantsMeta", eventId],
    queryFn: ({ signal }) => fetchParticipantsMeta(apiRoot, signal),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useParticipantsTable = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["participantsTable", eventId],
    queryFn: () => fetchParticipantsTable(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useAddParticipant = (eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: (_data, variables) => {
      addParticipantToCache(queryClient, eventId, variables.participant);
      queryClient.invalidateQueries(["participantsTable", eventId]);
    }
  });
};

export const useDeleteParticipant = (apiRoot, eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id }) => deleteParticipant(apiRoot, id),
    onSuccess: (_data, variables) => {
      removeParticipantFromCache(queryClient, eventId, variables.id);
      queryClient.invalidateQueries(["participantsTable", eventId]);
    }
  });
};

export const useRefreshParticipants = (eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["participantsTable", eventId]);
    }
  });
};

export const useRefreshParticipantsMeta = (eventId) => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({ queryKey: ["participantsMeta", eventId] });
  };
};

export const useUpdateParticipant = (eventId) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: (_data, variables) => {
      updateParticipantInCache(queryClient, eventId, variables.participant);
      queryClient.invalidateQueries(["participantsTable", eventId]);
    }
  });
};
