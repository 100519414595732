function isDeveloper(user) {
  return user.role === "executive" || user.role === "developer";
}

function isPrivileged(user) {
  return (
    user?.role === "admin" || user?.role === "super_admin" || user?.role === "executive" || user?.role === "developer"
  );
}

export { isDeveloper, isPrivileged };
