import React from "react";
import PropTypes from "prop-types";

import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

import MuiSelectClearable from "@shared/MuiSelectClearable";
const WidgetEmailTemplateSelect = props => {
  const { control, templates } = props;
  return (
    <div className="mb-4 mt-4">
      <div className="mb-4 w-full">
        <FormControl fullWidth>
          <FormLabel>Select email template</FormLabel>
          <Controller
            name="widget_config[email_template_id]"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <Select
                {...field}
                fullWidth
                onChange={onChange}
                value={value}
                variant="outlined"
                size="small"
                MenuProps={{ disableScrollLock: true }}
                endAdornment={(
                  <MuiSelectClearable value={value} onChange={onChange} />
                )}
              >
                {templates.map(template => (
                  <MenuItem key={`template-${template.id}`} value={template.id}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};

WidgetEmailTemplateSelect.propTypes = {
  control: PropTypes.object.isRequired,
  templates: PropTypes.array.isRequired
};

export default WidgetEmailTemplateSelect;
