import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertSuccess } from "@shared/Alerts";
import { renderButton, renderCancelButton } from "@shared/FormUtils";

const PeopleEventParticipantsTagDialog = (props) => {
  const { goToSettings, open, onClose, onSuccess, selectedParticipants, tags } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [selectedTags, setSelectedTags] = useState([]);

  const tagOptions = useMemo(() => tags.map((t) => ({ id: t.id, value: t.gid, label: t.name })), [tags]);

  const handleChange = (_evt, tagList) => {
    setSelectedTags(tagList.map((t) => t.value));
  };

  const handleSubmit = () => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    const formData = new FormData();
    formData.set("assignment[tags]", selectedTags.join(","));
    formData.set("assignment[participants]", selectedParticipants.join(","));
    axios({
      method: "POST",
      url: urljoin(apiRoot, "/participant_tags/assign"),
      data: formData
    })
      .then((result) => {
        onSuccess();
        alertSuccess(`Tags assigned to ${selectedParticipants.length} participants`);
        setSelectedTags([]);
        if (ESB_NODE_ENV === "development") {
          console.log(result.data);
        }
        onClose();
      })
      .catch((error) => {
        alertError("Failed to assign tags");
        console.log(error);
      });
  };

  return (
    <Dialog open={open} variant="outlined">
      <DialogTitle className="w-[580px] bg-ui-7 text-white">Assign Tags</DialogTitle>

      <DialogContent className="py-8">
        <Autocomplete
          className="mb-2"
          ChipProps={{ color: "primary" }}
          filterSelectedOptions
          multiple
          onChange={handleChange}
          options={tagOptions}
          renderInput={(params) => <TextField {...params} label="Select Tag(s)" placeholder="Tag name" />}
        />
        <DialogContentText>
          Don’t see the tag you are looking for? Please go to&nbsp;
          <span className="cursor-pointer text-ui-7" onClick={goToSettings}>
            Settings
          </span>{" "}
          to add it.
        </DialogContentText>
        <DialogActions className="justify-start px-0 pt-4">
          {renderButton("Assign Tags", () => {
            console.log(`tag gids: ${selectedTags}`);
            console.log(`part gids: ${selectedParticipants}`);

            if (selectedTags.length === 0) {
              alertError("Please select at least one tag");
              return;
            }
            handleSubmit();
          })}
          {renderCancelButton("Cancel", onClose)}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

PeopleEventParticipantsTagDialog.propTypes = {
  goToSettings: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  selectedParticipants: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired
};

export default PeopleEventParticipantsTagDialog;
