import React from "react";

import TicketingPackageTypeForm from "./TicketingPackageTypeForm";

const TicketingPackageTypeNew = (props) => {
  const { cancelButton, callbackFailure, callbackSuccess } = props;

  return (
    <TicketingPackageTypeForm
      cancel={cancelButton}
      callbackSuccess={callbackSuccess}
      callbackFailure={callbackFailure}
    />
  );
};

export default TicketingPackageTypeNew;
