import React, { useContext } from "react";

import clsx from "clsx";
import { NavLink } from "react-router";

import EventUserContext from "../EventUserContext";

const EventMenuItem = (props) => {
  const { link = "/", icon = "tile", title = "Tile Tile", requiredPermissions = [] } = props;
  const { user } = useContext(EventUserContext);
  const linkStyle = { textDecoration: "none" };
  const iconUrl = `/images/nav/${icon}.svg`;

  const hasRequiredAccess = () => {
    if (user.role !== "basic") {
      return true;
    }
    for (const permission of requiredPermissions) {
      if (!user.permission[permission]) {
        return false;
      }
    }
    return true;
  };

  if (hasRequiredAccess()) {
    return (
      <NavLink
        className={({ isActive }) => clsx("sg-mgmt-menu-nav-link", isActive ? "sg-mgmt-menu-nav-link-active" : "")}
        style={linkStyle}
        to={link}
      >
        <div className="sg-mgmt-menu-item flex items-center">
          <div className="flex h-[50px] w-[50px] shrink-0 items-center justify-center">
            <img
              className="sg-mgmt-menu-item-icon sg-mgmt-menu-item-icon-inactive h-auto w-[22px] cursor-pointer"
              src={iconUrl}
              alt={`${title} nav icon`}
            />
          </div>
          <div className="sg-mgmt-menu-item-title font-['Roboto'] text-base font-medium">{title}</div>
        </div>
      </NavLink>
    );
  }
  return <div></div>;
};

export default EventMenuItem;
