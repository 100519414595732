import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchIndividuals = async (rootUrl, signal) => {
  return request({
    method: "get",
    url: urljoin(rootUrl, "/-/individuals"),
    signal: signal
  });
};

// const addIndividual = async (rootUrl, data) => {
//   return request({
//     method: "post",
//     url: urljoin(rootUrl, "/-/individuals"),
//     data: data
//   });
// };

export const useIndividuals = (rootUrl) => {
  return useQuery({
    queryKey: ["individuals"],
    queryFn: ({ signal }) => fetchIndividuals(rootUrl, signal),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useAddIndividual = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: (_data, variables) => {
      queryClient.setQueryData(["individuals"], (existing) => {
        const newIndividuals = [...existing.individuals, variables.individual];
        return { individuals: newIndividuals };
      });
    }
  });
};
