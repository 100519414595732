import React from "react";

import EventApisIntegrationsMarketo from "./marketo/EventApisIntegrationsMarketo";

const EventApisIntegrations = () => {
  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">
      <h1>Integrations</h1>
      <p>Marketo:</p>
      <EventApisIntegrationsMarketo />
    </div>
  );
};

export default EventApisIntegrations;
