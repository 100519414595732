import React from "react";

import { Close as CloseIcon, Menu as MenuIcon } from "@mui/icons-material";

const EventMenuExpandRetract = (props) => {
  const { menuExpanded, onClick } = props;

  return (
    <div className="sg-mgmt-menu-nav-link sg-mgmt-menu-expand-retract mt-[50px]" onClick={onClick}>
      <div className="sg-mgmt-menu-item flex items-center">
        <div className="flex h-[50px] w-[50px] shrink-0 items-center justify-center">
          {menuExpanded && (
            <CloseIcon
              className="sg-mgmt-menu-item-icon sg-mgmt-menu-item-icon-inactive h-auto w-[22px] cursor-pointer"
              alt={`Expand Retract Menu`}
            />
          )}
          {!menuExpanded && (
            <MenuIcon
              className="sg-mgmt-menu-item-icon sg-mgmt-menu-item-icon-inactive h-auto w-[22px] cursor-pointer"
              alt={`Expand Retract Menu`}
            />
          )}
        </div>
        <div className="sg-mgmt-menu-item-title font-['Roboto'] text-base font-medium">
          <span>{menuExpanded ? "Collapse" : "Expand"} Menu</span>
        </div>
      </div>
    </div>
  );
};

export default EventMenuExpandRetract;
