import React, { useContext, useState } from "react";

import EventContext from "@event/EventContext";
import { useHousingBlocks, useHousingHotels } from "@shared/hooks/useHousing";
import PageHeader from "@shared/PageHeader";

import HousingRoomBlock from "./HousingRoomBlock";
import HousingRoomBlockInventory from "./HousingRoomBlockInventory";
import HousingRoomBlocksIndex from "./HousingRoomBlocksIndex";

const HousingRoomBlocks = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const [page, setPage] = useState("index");
  const [editBlockId, setEditBlockId] = useState(null);
  const blocksQuery = useHousingBlocks(apiRoot, event.id);
  const hotelsQuery = useHousingHotels(apiRoot, event.id);

  const goToPage = (p) => {
    setPage(p);
  };

  const renderPage = () => {
    switch (page) {
      case "index":
        return (
          <HousingRoomBlocksIndex
            blocks={blocksQuery.data.blocks}
            config={hotelsQuery.data.configuration}
            setEditBlockId={setEditBlockId}
            hotels={hotelsQuery.data.hotels}
            goEdit={() => goToPage("edit")}
            goRooms={() => goToPage("room_types")}
          />
        );
      case "edit":
        return (
          <HousingRoomBlock
            config={hotelsQuery.data.configuration}
            blocks={blocksQuery.data.blocks}
            editBlockId={editBlockId}
            goIndex={() => goToPage("index")}
            setEditBlockId={setEditBlockId}
          />
        );
      case "room_types":
        return (
          <HousingRoomBlockInventory
            blocks={blocksQuery.data.blocks}
            config={hotelsQuery.data.configuration}
            block={blocksQuery.data.blocks.find((ele) => ele.id === editBlockId)}
            goIndex={() => goToPage("index")}
          />
        );
      default:
        throw new Error("invalid housing page");
    }
  };

  return (
    <div>
      <PageHeader text="Room Blocks" />
      {renderPage()}
    </div>
  );
};

export default HousingRoomBlocks;
