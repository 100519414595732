import React from "react";

import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import { createBrowserRouter } from "react-router";
import { RouterProvider } from "react-router/dom";

import { mainRoutes } from "@routes/mainRoutes";
import ErrorBoundary from "@shared/ErrorBoundary";
import MuiAlert from "@shared/MuiAlert";

const MainApp = (props) => {
  const { rootUrl, urlBase } = props;

  const queryClient = new QueryClient();
  const router = createBrowserRouter(mainRoutes(rootUrl, props), {
    basename: urlBase
  });

  return (
    <ErrorBoundary>
      <SnackbarProvider
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3500}
        Components={{
          mui: MuiAlert
        }}
      >
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </SnackbarProvider>
    </ErrorBoundary>
  );
};

export default MainApp;
