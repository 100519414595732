import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCancelButton } from "@shared/FormUtils";
import GrowlModal from "@shared/GrowlModal";

import CommunicationsEmailTemplateTagsAdd from "./CommunicationsEmailTemplateTagsAdd";

const CommunicationsEmailTemplateTags = (props) => {
  const { apiRoot } = useContext(EventContext).values;
  const { back } = props;
  const [tags, setTags] = useState([]);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "template_tags"));
        setTags(result.data.tags);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchTags();
  }, [apiRoot]);

  const addTag = (tag) => {
    setTags([tag].concat(tags));
  };

  const deleteTag = (tagId) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      url: urljoin(apiRoot, "/template_tags", `/${tagId}`),
      method: "DELETE",
      data: {}
    }).then((response) => {
      if (response.data.error === null) {
        setTags(tags.filter((t) => t.id !== tagId));
        alertSuccess("Tag deleted");
      } else {
        alertError(response.data.error);
      }
    });
  };

  const renderTags = () => {
    if (fetched) {
      return (
        <div>
          <div className="my-4 flex">
            <div className="mr-2">Tags:</div>
            {tags.map((tag) => (
              <div
                className="mr-2 flex-initial bg-sg-orange bg-opacity-70 px-2 py-1 text-white"
                key={`tag-${tag.gid}-${tag.id}`}
              >
                {tag.name}
                <GrowlModal
                  content="Are you sure you want to delete this tag?"
                  title="Delete Tag"
                  trigger={<div className="ml-1 inline-block cursor-pointer">&times;</div>}
                  actions={[
                    {
                      label: "Cancel",
                      close: true
                    },
                    {
                      label: "Delete",
                      close: true,
                      func: () => {
                        deleteTag(tag.id);
                      }
                    }
                  ]}
                />
              </div>
            ))}
          </div>
        </div>
      );
    }
    return <h2>Loading</h2>;
  };

  return (
    <div className="sg-mgmt-content-frame">
      <div className="sg-mgmt-content sg-mgmt-content-full">
        <h2>Manage Tags</h2>
        <CommunicationsEmailTemplateTagsAdd update={addTag} />
        {renderTags()}
        {renderCancelButton("Return", back)}
      </div>
    </div>
  );
};

export default CommunicationsEmailTemplateTags;
