import React, { useContext } from "react";

import EventUserContext from "@event/EventUserContext";
import RouterTabbedPage from "@shared/RouterTabbedPage";
import { TabSettingsIcon } from "@shared/tabs/TabIcons";

import PostmarkApiValidator from "./postmark/PostmarkApiValidator";

const Communications = () => {
  const { user } = useContext(EventUserContext);

  const getTabs = () => {
    let tabs = [
      { label: "Email", path: "/communications" },
      { label: "Email Templates", path: "/communications/templates" },
      { label: "Mailing Lists", path: "/communications/mailing_lists" }
    ];

    if (user && (user.role != "basic" || user.permission.communications_edit)) {
      tabs.push({ label: "Settings", path: "/communications/settings", icon: TabSettingsIcon });
    }
    return tabs;
  };

  return (
    <>
      <PostmarkApiValidator />
      <RouterTabbedPage tabs={getTabs()} />
    </>
  );
};

export default Communications;
