import React, { useContext, useState } from "react";

import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import { useTicketPackageTypes, useDeleteTicketPackageType } from "@shared/hooks/useTicketPackages";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { formatTimeUtcToZone } from "@shared/TimeUtils";

import { packageTotalAssigned } from "./PackageUtils";
import TicketingPackageTypeFormModal from "./TicketingPackageTypeFormModal";
import TicketingPackageTypeHistoryModal from "./TicketingPackageTypeHistoryModal";

const TicketingPackages = () => {
  const { user } = useContext(EventUserContext);
  const { apiRoot, event } = useContext(EventContext).values;
  const [packageTypeModalOpen, setPackageTypeModalOpen] = useState(false);
  const [packageTypeModalTarget, setPackageTypeModalTarget] = useState(null);
  const [packageTypeHistoryModalOpen, setPackageTypeHistoryModalOpen] = useState(false);
  const [packageTypeHistoryModalTarget, setPackageTypeHistoryModalTarget] = useState(null);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const ticketPackageTypes = useTicketPackageTypes(apiRoot, event.id);
  const deleteTicketPackageType = useDeleteTicketPackageType(apiRoot, event.id);

  const performDelete = (id) => {
    deleteTicketPackageType.mutate(
      { id: id },
      {
        onSuccess: (data) => {
          if (data.error === null) {
            alertSuccess("Package deleted successfully");
          } else {
            alertError(data.error);
          }
        },
        onError: (error) => {
          alertError(error);
        }
      }
    );
  };

  const renderViewAction = (id) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            viewPackage(id);
          }}
        >
          Assignments
        </span>
      </>
    );
  };

  const viewPackage = (packageType) => {
    //TODO go to edit page
    navigate(`/ticketing/packages/${packageType}`);
  };

  const renderHistoryAction = (id) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            setPackageTypeHistoryModalTarget(id);
            setPackageTypeHistoryModalOpen(true);
          }}
        >
          History
        </span>
      </>
    );
  };

  const renderEditAction = (id) => {
    if (editEnabled()) {
      return (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              openEditPackageTypesModal(id);
            }}
          >
            Edit
          </span>
        </>
      );
    }
    return <></>;
  };

  const renderDeleteAction = (id) => {
    if (editEnabled()) {
      return (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              confirm({
                title: "Confirm delete",
                description: "Are you sure you want to delete this package?"
              })
                .then(() => {
                  performDelete(id);
                })
                .catch((err) => {
                  if (err) alertError(err);
                });
            }}
          >
            Delete
          </span>
        </>
      );
    }
    return <></>;
  };

  const columns = [
    {
      headerName: "Package Name",
      field: "name"
    },
    {
      headerName: "Total Count",
      field: "total_count"
    },
    {
      headerName: "Total Assigned",
      field: "total_assigned",
      renderCell: (params) => packageTotalAssigned(params.row)
    },
    {
      headerName: "Updated At",
      field: "updated_at",
      minWidth: 200,
      renderCell: (params) => {
        return formatTimeUtcToZone(params.value, event.time_zone);
      }
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: 320,
      getActions: (params) => [
        renderViewAction(params.id),
        renderHistoryAction(params.id),
        renderEditAction(params.id),
        renderDeleteAction(params.id)
      ]
    }
  ];

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.ticketing_edit) {
      return false;
    }
    return true;
  };

  const openNewPackageTypesModal = () => {
    setPackageTypeModalTarget(null);
    setPackageTypeModalOpen(true);
  };

  const openEditPackageTypesModal = (id) => {
    setPackageTypeModalTarget(id);
    setPackageTypeModalOpen(true);
  };

  const closePackageTypesModal = () => {
    setPackageTypeModalOpen(false);
    setPackageTypeModalTarget(null);
  };

  if (ticketPackageTypes.isLoading) {
    return <Loading />;
  }

  if (ticketPackageTypes.isError) {
    return <div>Error: {ticketPackageTypes.error}</div>;
  }

  return (
    <>
      <div>
        <PageHeader text="Packages Overview" />
        <p className="mb-4">
          Group multiple ticket types into packages, if you want to assign a set combination of tickets.
          <br />
          Note: In order to create a package, you need to create the ticket types first.
        </p>
        {editEnabled() && renderCreateButton("Create New Package Type", openNewPackageTypesModal)}
        <div className="mt-4">
          <GrowlTable
            columns={columns}
            items={ticketPackageTypes.data.package_types}
            sortField="name"
            sortDirection="asc"
            tableName={`${event.slug}-ticket-packages`}
          />
        </div>
        <TicketingPackageTypeFormModal
          packageTypeId={packageTypeModalTarget}
          packageTypes={ticketPackageTypes.data.package_types}
          modalVisible={packageTypeModalOpen}
          resetModal={closePackageTypesModal}
          callbackFailure={() => {}}
          callbackSuccess={() => {
            closePackageTypesModal();
          }}
        />
        <TicketingPackageTypeHistoryModal
          packageTypeId={packageTypeHistoryModalTarget}
          modalVisible={packageTypeHistoryModalOpen}
          resetModal={() => {
            setPackageTypeHistoryModalOpen(false);
            setPackageTypeHistoryModalTarget(null);
          }}
        />
      </div>
    </>
  );
};

export default TicketingPackages;
