import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import urljoin from "url-join";

import WidgetColorPicker from "@dashboard/form/WidgetColorPicker";
import WidgetDataSetSelect from "@dashboard/form/WidgetDataSetSelect";
import WidgetFilterByMetadata from "@dashboard/form/WidgetFilterByMetadata";
import WidgetFilterByRole from "@dashboard/form/WidgetFilterByRole";
import WidgetFilterByStatus from "@dashboard/form/WidgetFilterByStatus";
import WidgetFilterByTag from "@dashboard/form/WidgetFilterByTag";
import WidgetFilterByTicket from "@dashboard/form/WidgetFilterByTicket";
import WidgetFilterByType from "@dashboard/form/WidgetFilterByType";
import WidgetHeaderField from "@dashboard/form/WidgetHeaderField";
import WidgetSettingsButtons from "@dashboard/form/WidgetSettingsButtons";
import WidgetSizeOptions from "@dashboard/form/WidgetSizeOptions";
import WidgetBoardContext from "@dashboard/WidgetBoardContext";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";

const BubbleWidgetSettings = (props) => {
  const { closeModal } = props;
  const { onUpdate, widget } = useContext(WidgetContext);
  const { widgetBoard, tags, types, metadataFields, statuses } = useContext(WidgetBoardContext);
  const { apiRoot } = useContext(EventContext).values;

  // HACK: these values will be fetched from FetchOptions service, but we've added them
  // here for instant loading when they're a known, pre-defined set.
  // TODO: Replace this with some kind of pre-loading from the service?
  const [dataSetOptions, setDataSetOptions] = useState({
    participantStatus: [
      { value: "preregistered", label: "Pre-registered", order: 0 },
      { value: "registered", label: "Registered", order: 1 },
      { value: "declined", label: "Declined", order: 2 },
      { value: "disallowed", label: "Disallowed", order: 3 },
      { value: "cancelled", label: "Cancelled", order: 4 },
      { value: "total", label: "Total", order: 5 }
    ],
    participantType: [],
    surveysActivity: [],
    ticketsActivity: [],
    packagesActivity: []
  });

  const [ticketOptions, setTicketOptions] = useState([]);

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      widget_data_set: widget?.widget_data_set || "participantStatus",
      widget_header: widget?.widget_header || "Header",
      widget_position: widget?.widget_position || 0,
      widget_size: widget?.widget_size || 1,
      widget_config: {
        bubbles: widget?.widget_config?.bubbles || {},
        filters: widget?.widget_config?.filters || {
          role: "",
          tag: "",
          type: "",
          metadata: "",
          ticket: ""
        }
      }
    }
  });

  const watchers = watch();
  const dataSet = watch("widget_data_set");

  useQuery({
    queryKey: ["dataSetOptions", dataSet],
    staleTime: 10000, // 10 seconds
    cacheTime: 36000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/dashboard/", `${widgetBoard.id}`, "/dataset_options", `/${dataSet}`), {
          signal
        })
        .then((res) => {
          // console.log(res);
          setDataSetOptions({
            ...dataSetOptions,
            [dataSet]: res.data.options
          });
          return res.data;
        })
        .catch((error) => {
          alertHttpError(error);
        })
  });

  useQuery({
    queryKey: ["ticketOptions"],
    staleTime: 10 * 1000, // 10 seconds
    cacheTime: 10 * 60 * 1000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/ticketing/ticket_types"), { signal })
        .then((res) => setTicketOptions(res.data.ticket_types))
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const selectorColors = [
    "#1A206C",
    "#1B5EC1",
    "#2196F3",
    "#FDB92A",
    "#F89728",
    "#D2450F",
    "#96BC33",
    "#557218",
    "#737373"
  ];

  const dataSets = [
    { value: "participantStatus", label: "Participant Status" },
    { value: "participantType", label: "Participant Type" },
    { value: "surveysActivity", label: "Surveys" },
    { value: "ticketsActivity", label: "Tickets" },
    { value: "packagesActivity", label: "Packages" }
  ];

  const formConfig = (() => {
    if (widget.id) {
      return {
        method: "PATCH",
        url: urljoin(apiRoot, "/widgets", `/${widget.id}`)
      };
    }
    return {
      method: "POST",
      url: urljoin(apiRoot, "/widgets")
    };
  })();

  const submitFn = (submitData) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    Object.entries(submitData.widget_config.bubbles[dataSet]).forEach(([key, bubble]) => {
      if (bubble.selected) {
        const option = dataSetOptions[dataSet].find((data) => data.value.toString() === key);

        // if it's not in the data set, purge it
        if (!option) {
          delete submitData.widget_config.bubbles[dataSet][key];
          return;
        }
        // otherwise, set the label
        bubble.label = option.label;

        // if dataset has order values, set the order
        if (option.order !== undefined) {
          bubble.order = option.order;
        }
      } else {
        delete submitData.widget_config.bubbles[dataSet][key];
      }
    });

    // purge bubble configs from previously selected dataSets
    Object.entries(submitData.widget_config.bubbles).forEach(([key, _values]) => {
      if (key !== dataSet) {
        delete submitData.widget_config.bubbles[key];
      }
    });

    // add in type
    submitData.widget_type = "bubble";

    axios({
      method: formConfig.method,
      url: formConfig.url,
      data: {
        widget: submitData,
        widget_board_id: widgetBoard.id
      }
    })
      .then((_result) => {
        onUpdate();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderBubbleSelections = () => {
    return (
      <div className="pb-12">
        <h3>Select from the options below</h3>
        {dataSetOptions[dataSet].map((data) => {
          return (
            <div className="flex flex-row" key={`${dataSet}-dataset-${data.value}`}>
              <div className="w-48">
                <FormControl>
                  <Controller
                    name={`widget_config[bubbles][${dataSet}][${data.value}][selected]`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label={data.label}
                        control={
                          <Checkbox checked={value} onChange={onChange} value={value} variant="outlined" size="small" />
                        }
                      />
                    )}
                  />
                </FormControl>
              </div>
              <div className="mt-2 flex-1 flex-grow align-bottom">
                {watchers?.widget_config?.bubbles?.[dataSet]?.[data.value]?.selected && (
                  <FormControl>
                    <Controller
                      name={`widget_config[bubbles][${dataSet}][${data.value}][color]`}
                      control={control}
                      defaultValue={"#1A206C"}
                      render={({ field: { value, onChange } }) => (
                        <WidgetColorPicker color={value} colors={selectorColors} onChange={onChange} circleSize={20} />
                      )}
                    />
                  </FormControl>
                )}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderFilters = () => {
    // console.log(ticketQuery);
    switch (dataSet) {
      case "participantStatus":
        return (
          <>
            <WidgetFilterByRole control={control} />
            <WidgetFilterByTag control={control} tags={tags} />
            <WidgetFilterByType control={control} types={types} />
            <WidgetFilterByMetadata control={control} metadataFields={metadataFields} watchers={watchers} />
            <WidgetFilterByTicket control={control} tickets={ticketOptions} />
          </>
        );
      case "participantType":
        return (
          <>
            <WidgetFilterByStatus control={control} statuses={statuses} />
            <WidgetFilterByTag control={control} tags={tags} />
            <WidgetFilterByType control={control} types={types} />
            <WidgetFilterByMetadata control={control} metadataFields={metadataFields} watchers={watchers} />
            <WidgetFilterByTicket control={control} tickets={ticketOptions} />
          </>
        );
      default:
        return <></>;
    }
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="mb-4 mt-4">
          <WidgetHeaderField control={control} errors={errors} />
          <WidgetDataSetSelect control={control} dataSets={dataSets} errors={errors} />
          {renderFilters()}
          <div>{renderBubbleSelections()}</div>
        </div>
        <WidgetSizeOptions control={control} />
        <WidgetSettingsButtons closeModal={closeModal} isSubmitDisabled={isSubmitting} isEdit={!!widget?.id} />
      </form>
    );
  };

  return (
    <div className="group relative bg-white">
      <div>
        <h2>Bubble widget</h2>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};

BubbleWidgetSettings.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default BubbleWidgetSettings;
