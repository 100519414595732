import React, { useContext, useEffect, useState } from "react";

import { Stack } from "@mui/material";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderTextField, renderSubmitButton, renderCancelButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";

import CommunicationsBlankEmailMailingListEntry from "./CommunicationsBlankEmailMailingListEntry";
import CommunicationsEmailMailingListEntryModal from "./CommunicationsEmailMailingListEntryModal";
import CommunicationsEmailMailingListImportLogModal from "./import/CommunicationsEmailMailingListImportLogModal";

const CommunicationsEmailMailingList = (props) => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { back = () => {}, listId, goImport } = props;
  const [list, setList] = useState({});
  const [entries, setEntries] = useState([]);
  const [editEntry, setEditEntry] = useState(CommunicationsBlankEmailMailingListEntry);
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [modalImportLogVisible, setModalImportLogVisible] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchParticipants = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "participants/table"));
        setParticipants(result.data.participants);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchMailingList = async () => {
      try {
        const result = await axios(urljoin(apiRoot, `/communications/lists/${listId}`));
        setList(result.data.list);
        setEntries(result.data.list.email_mailing_list_entries);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchMailingList();
    fetchParticipants();
  }, [apiRoot, listId]);

  const nameFormConfig = (() => {
    return {
      alert: "updated",
      formId: "sg-mgmt-form-email-edit",
      formUrl: urljoin(apiRoot, "/communications/lists", `/${list.id}`),
      method: "PATCH",
      saveButton: "Save",
      title: "Edit Mailing List"
    };
  })();

  const addEntry = (entry) => {
    const newEntries = entries.concat(entry);
    setEntries(newEntries);
  };

  const updateEntry = (entry) => {
    const newEntries = entries.map((recip) => (recip.gid === entry.gid ? entry : recip));
    setEntries(newEntries);
  };

  const removeEntry = (id) => {
    const newEntries = entries.filter((r) => r.id !== id);
    setEntries(newEntries);
  };

  const updateList = (list) => {
    setList(list);
  };

  const modalEditClose = () => {
    setEditEntry(CommunicationsBlankEmailMailingListEntry);
    setModalEditVisible(false);
  };

  const editClick = (entry) => {
    setEditEntry(entry);
    setModalEditVisible(true);
  };

  const modalAddClose = () => {
    setModalAddVisible(false);
  };

  const addClick = () => {
    setModalAddVisible(true);
  };

  const performDelete = (id) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, `/communications/lists/${listId}/entries/${id}`),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error == null) {
          removeEntry(id);
          alertSuccess("Entry deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const renderEditLink = (entry) => {
    return (
      <div
        className="sg-mgmt-link sg-mgmt-table-link"
        onClick={() => {
          editClick(entry);
        }}
      >
        Edit
      </div>
    );
  };

  const renderDeleteLink = (entry) => {
    return (
      <div
        className="sg-mgmt-link sg-mgmt-table-link"
        onClick={() => {
          performDelete(entry.id);
        }}
      >
        Remove
      </div>
    );
  };

  const renderEntryEmails = (entry) => {
    const renderList = [];
    renderList.push(<React.Fragment key={entry.email}>{entry.email}</React.Fragment>);
    if (entry.cc_list && entry.cc_list.length > 0) {
      renderList.push(
        <div className="sg-mgmt-table-entries-cc" key={`${entry.email}-cclist`}>
          cc: {entry.cc_list}
        </div>
      );
    }
    if (entry.bcc_list && entry.bcc_list.length > 0) {
      renderList.push(
        <div className="sg-mgmt-table-entries-cc" key={`${entry.email}-bcclist`}>
          bcc: {entry.bcc_list}
        </div>
      );
    }
    if (entry.custom_tag_1 && entry.custom_tag_1.length > 0) {
      renderList.push(
        <div className="sg-mgmt-table-entries-cc" key={`${entry.email}-merge1`}>
          *|CUSTOM1|*: {entry.custom_tag_1}
        </div>
      );
    }
    if (entry.custom_tag_2 && entry.custom_tag_2.length > 0) {
      renderList.push(
        <div className="sg-mgmt-table-entries-cc" key={`${entry.email}-merge2`}>
          *|CUSTOM2|*: {entry.custom_tag_2}
        </div>
      );
    }
    if (entry.custom_tag_3 && entry.custom_tag_3.length > 0) {
      renderList.push(
        <div className="sg-mgmt-table-entries-cc" key={`${entry.email}-merge3`}>
          *|CUSTOM3|*: {entry.custom_tag_3}
        </div>
      );
    }
    if (entry.custom_tag_4 && entry.custom_tag_4.length > 0) {
      renderList.push(
        <div className="sg-mgmt-table-entries-cc" key={`${entry.email}-merge4`}>
          *|CUSTOM4|*: {entry.custom_tag_4}
        </div>
      );
    }
    if (entry.custom_tag_5 && entry.custom_tag_5.length > 0) {
      renderList.push(
        <div className="sg-mgmt-table-entries-cc" key={`${entry.email}-merge5`}>
          *|CUSTOM5|*: {entry.custom_tag_5}
        </div>
      );
    }

    return renderList;
  };

  const filteredEntries = () => {
    return entries.filter((entry) => Object.keys(entry).find((key) => new RegExp(search).test(entry[key])));
  };

  const renderEntries = () => {
    const filtered = filteredEntries();
    return (
      <>
        {filtered.map((entry) => (
          <tr key={entry.gid}>
            <td className="w-1/3 border-t px-4 py-2">{renderEntryEmails(entry)}</td>
            <td className="w-1/6 border-t px-4 py-2">{entry.name_first}</td>
            <td className="w-1/6 border-t px-4 py-2">{entry.name_last}</td>
            <td className="w-1/3 border-t px-4 py-2 text-right">
              {renderEditLink(entry)} | {renderDeleteLink(entry)}
            </td>
          </tr>
        ))}
        {filtered.length == 0 ? (
          <tr key="no-entries">
            <td colSpan="5" style={{ textAlign: "center", fontStyle: "italic" }}>
              No entries.
            </td>
          </tr>
        ) : (
          <></>
        )}
      </>
    );
  };

  const renderNameForm = () => {
    if (fetched) {
      return (
        <Formik
          initialValues={{
            list: {
              name: list.name || "no name"
            }
          }}
          onSubmit={(values, { setSubmitting }) => {
            const form = document.getElementById(nameFormConfig.formId);
            const formData = new FormData(form);

            const token = document.querySelector("[name=csrf-token]").content;
            axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
            axios({
              url: nameFormConfig.formUrl,
              method: nameFormConfig.method,
              data: formData
            })
              .then((response) => {
                if (response.data.error === null) {
                  updateList(response.data.list);
                  alertSuccess("Updated mailing list name");
                  setSubmitting(false);
                } else {
                  alertError(response.data.error);
                  setSubmitting(false);
                }
              })
              .catch((error) => {
                alertHttpError(error);
              });
          }}
        >
          {({ isSubmitting }) => (
            <Form className="sg-mgmt-form" id={nameFormConfig.formId}>
              <div className="sg-mgmt-form-container">
                <div className="flex items-end">
                  <div className="w-1/2">
                    <Stack spacing={2} direction="row">
                      {renderTextField("Mailing List Name", "list[name]", [], false, true, {})}
                      <div className="pt-4">{renderSubmitButton(nameFormConfig.saveButton, isSubmitting)}</div>
                    </Stack>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      );
    }
    return <Loading />;
  };

  const renderMailingList = () => {
    if (fetched) {
      return (
        <div>
          <table className="sg-mgmt-table">
            <thead>
              <tr>
                <th className="bg-ui-3 px-4 py-2 text-left text-white">Email Address</th>
                <th className="bg-ui-3 px-4 py-2 text-left text-white">First Name</th>
                <th className="bg-ui-3 px-4 py-2 text-left text-white">Last Name</th>
                <th className="bg-ui-3 px-4 py-2 text-right text-white">Actions</th>
              </tr>
            </thead>
            <tbody>{renderEntries()}</tbody>
          </table>
        </div>
      );
    }
    return <Loading />;
  };

  const renderImportExport = () => (
    <div className="flex-1">
      <span className="sg-mgmt-link" data-turbolinks="false" onClick={addClick}>
        Add Entry
      </span>
      &nbsp;|&nbsp;
      <span className="sg-mgmt-link" data-turbolinks="false" onClick={goImport}>
        Import from Spreadsheet
      </span>
      &nbsp;|&nbsp;
      <span className="sg-mgmt-link" data-turbolinks="false" onClick={() => setModalImportLogVisible(true)}>
        View Import Log
      </span>
      &nbsp;|&nbsp;
      <a
        className="sg-mgmt-link"
        href={`/reporting/events/${event.slug}/emails/mailing_list/${list.gid}`}
        data-turbolinks="false"
      >
        Export Mailing List
      </a>
    </div>
  );

  const renderSearch = () => (
    <div className="flex-none">
      <input
        className="sg-mgmt-form-input"
        type="text"
        placeholder="Search"
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
  );

  return (
    <div>
      <h1>{list.name}</h1>
      {renderNameForm()}
      <div className="mb-2 flex items-end">
        {renderImportExport()}
        {renderSearch()}
      </div>
      {renderMailingList()}
      <br />
      {renderCancelButton("Back", back)}
      <CommunicationsEmailMailingListEntryModal
        entry={editEntry}
        listId={listId}
        modalVisible={modalEditVisible}
        participants={participants}
        resetModal={modalEditClose}
        title="Edit Entry"
        updateFunc={updateEntry}
      />
      <CommunicationsEmailMailingListEntryModal
        entry={CommunicationsBlankEmailMailingListEntry}
        listId={listId}
        modalVisible={modalAddVisible}
        resetModal={modalAddClose}
        participants={participants}
        title="Add Entry"
        updateFunc={addEntry}
      />
      <CommunicationsEmailMailingListImportLogModal
        listId={listId}
        modalVisible={modalImportLogVisible}
        modalClose={() => setModalImportLogVisible(false)}
      />
    </div>
  );
};

export default CommunicationsEmailMailingList;
