import React, { useContext } from "react";

import EventContext from "@event/EventContext";
import { useTicketTags } from "@shared/hooks/useTickets";
import Loading from "@shared/Loading";

import TicketingTicketTypeForm from "./TicketingTicketTypeForm";

const TicketingTicketTypeNew = (props) => {
  const { cancelButton, callbackFailure, callbackSuccess } = props;
  const { apiRoot } = useContext(EventContext).values;

  const ticketTags = useTicketTags(apiRoot);

  if (ticketTags.isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (ticketTags.isError) {
    return <div>Error: {ticketTags.error}</div>;
  }

  return (
    <TicketingTicketTypeForm
      cancel={cancelButton}
      callbackSuccess={callbackSuccess}
      callbackFailure={callbackFailure}
      ticketTags={ticketTags.data.tags}
    />
  );
};

export default TicketingTicketTypeNew;
