import React from "react";

import { Autocomplete, TextField } from "@mui/material";
import clsx from "clsx";
import { Field } from "formik";

import RequiredAsterisk from "@shared/forms/RequiredAsterisk";
import HelperIcon from "@shared/helpers/HelperIcon";

import SelectFieldVirtualizedList from "./SelectFieldVirtualizedList";

const SelectField = (props) => {
  const {
    label = "",
    name = "",
    options = [],
    formatClasses = [],
    required = false,
    helper = false,
    helperProperties = {},
    labelAdditions,
    disableLabel,
    placeholder,
    disabled = false,
    includeBlank = false,
    onChange,
    virtualized
  } = props;

  const renderAutoComplete = ({ field, ...props }) => {
    const { options, form } = props;
    const selectedOption = options.find((option) => option.value == field.value) || null;

    return (
      <>
        <Autocomplete
          {...props}
          {...field}
          ListboxComponent={virtualized ? SelectFieldVirtualizedList : undefined}
          value={selectedOption}
          onChange={(e, newValue) => {
            newValue = newValue || { value: "" };

            form.setFieldValue(field.name, newValue.value);
            form.setFieldTouched(field.name, true);
            //field.onChange(e, newValue.value);
            if (onChange) {
              onChange(newValue.value);
            }
          }}
          renderInput={(params) => <TextField autoComplete="off" {...params} />}
          disablePortal
        />
        <input type="hidden" name={field.name} value={field.value} />
      </>
    );
  };

  const getOptions = () => {
    let new_options = options;
    if (includeBlank) {
      new_options.unshift({ label: "", value: "" });
    }
    return new_options;
  };

  return (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      {disableLabel ? (
        ""
      ) : (
        <label>
          {label}
          {required ? <RequiredAsterisk /> : <></>}
          {helper ? <HelperIcon {...(helperProperties || {})} /> : <></>}
          {labelAdditions ? labelAdditions : ""}
        </label>
      )}
      <Field
        component={renderAutoComplete}
        variant={"outlined"}
        options={getOptions()}
        size="small"
        fullWidth
        name={name}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SelectField;
