import React, { useContext, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import {
  renderCancelButton,
  renderSelectField,
  renderSubmitButton,
  renderTextField,
  renderTextAreaField
} from "@shared/FormUtils";
import Loading from "@shared/Loading";

const FeaturesForm = ({ featureId, cancel, callbackSuccess, callbackFailure }) => {
  const featureStatusOptions = [
    { label: "Disabled", value: "disabled" },
    { label: "Disabled - (Test Users Only)", value: "testers_only" },
    { label: "Enabled", value: "enabled" }
  ];
  const [feature, setFeature] = useState(null);
  const { apiRoot } = useContext(EventContext).values;

  const isEdit = () => {
    return featureId;
  };

  useEffect(() => {
    const fetchFeature = async () => {
      try {
        if (featureId) {
          const result = await axios(urljoin(apiRoot, `feature_flags/features/${featureId}`));
          setFeature(result.data["feature"]);
        } else {
          const result = await axios(urljoin(apiRoot, `feature_flags/features/new`));
          setFeature(result.data["feature"]);
        }
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchFeature();
  }, [apiRoot, featureId]);

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-forms-edit",
        formUrl: urljoin(apiRoot, "/feature_flags/features", `/${featureId}`),
        method: "PATCH",
        saveButton: "Save",
        title: "Edit " + ((feature || {}).name || "")
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-forms-add",
      formUrl: urljoin(apiRoot, "/feature_flags/features/"),
      method: "POST",
      saveButton: "Save",
      title: "Create New Feature"
    };
  })();

  const featureInitialValues = () => {
    return {
      name: feature ? feature.name : "",
      description: feature ? feature.description : "",
      slug: feature ? feature.slug : "",
      status: feature ? feature.status : "disabled"
    };
  };

  const formValidation = (values) => {
    const errors = {};
    if (!values.feature.name) {
      alert("You must enter a name.");
      errors.feature = errors.feature || {};
      errors.feature.name = "Required";
    }

    return errors;
  };

  const renderFormFields = (_formikProps) => {
    return (
      <div className="sg-mgmt-form-container">
        <div className="sg-mgmt-form-section">
          <div className="flex">
            <div className="mr-4 w-full">{renderTextField("Feature Name", "feature[name]", [], false, true)}</div>
          </div>
          <div className="flex">
            <div className="mr-4 w-full">{renderTextField("Feature Slug", "feature[slug]", [], false, true)}</div>
          </div>
          <div className="flex">
            <div className="mr-4 w-full">{renderTextAreaField("Feature Description", "feature[description]")}</div>
          </div>
          <div className="flex">
            <div className="mr-4 w-full">
              {renderSelectField("Feature Status", "feature[status]", featureStatusOptions)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          feature: featureInitialValues()
        }}
        validate={formValidation}
        validateOnChange={false}
        validateOnBlur={false}
        validateOnSubmit={true}
        onSubmit={(values, { setSubmitting }) => {
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: values
          })
            .then((response) => {
              if (response.data.error == null) {
                callbackSuccess ? callbackSuccess(response) : () => {};
                alertSuccess("Feature Saved");
                setFeature(response.data.feature);
                setSubmitting(false);
              } else {
                callbackFailure ? callbackFailure(response) : () => {};
                alertError(`Unable to save feature: ${response.data.error}`);
                setSubmitting(false);
              }
            })
            .catch((error) => {
              alertHttpError(error);
              setSubmitting(false);
            });
        }}
      >
        {(formikProps) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            {renderFormFields(formikProps)}
            {renderButtons(formikProps)}
          </Form>
        )}
      </Formik>
    );
  };

  const renderButtons = (formikProps) => {
    const { isSubmitting } = formikProps;
    return (
      <Stack direction="row" spacing={2}>
        {renderSubmitButton(formConfig.saveButton, isSubmitting)}
        {renderCancelButton("Cancel", cancel)}
      </Stack>
    );
  };

  return (
    <div>
      <h1>{formConfig.title}</h1>
      {feature ? renderForm() : <Loading />}
    </div>
  );
};

export default FeaturesForm;
