import React, { useContext, useState } from "react";

import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { eachDayOfInterval, parse, parseISO } from "date-fns";
import { format } from "date-fns-tz";
import { forEach } from "lodash";
import { useConfirm } from "material-ui-confirm";
import { Link } from "react-router";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import EventSessionModalView from "./EventSessionModalView";
import EventSessionsModalAdd from "./EventSessionsModalAdd";
import EventSessionsTable from "./EventSessionsTable";

const EventSessions = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const confirm = useConfirm();
  const queryClient = useQueryClient();

  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [modalViewVisible, setModalViewVisible] = useState(false);
  const [viewSessionId, setViewSessionId] = useState(null);

  const { isPending, error, data } = useQuery({
    queryKey: ["sessions"],
    staleTime: 10000, // 10 seconds
    cacheTime: 36000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/sessions"), { signal })
        .then((res) => res.data)
        .catch(() => {
          alertHttpError(error);
        })
  });

  const refreshSessions = useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: () => {
      queryClient.invalidateQueries("sessions");
    }
  });

  const timeParse = (time) => {
    return parse(time, "h:mm a", new Date());
  };

  const tableColumns = () => {
    const columnArray = [
      {
        headerName: "Title",
        field: "title",
        flex: 1
      },
      {
        headerName: "Start Time",
        field: "start_time",
        flex: 1,
        valueGetter: (params) => timeParse(params.row.time_formatted).getTime(),
        renderCell: (params) => params.row.time_formatted
      }
    ];
    columnArray.push({
      headerName: "Room",
      field: "room_name",
      type: "singleSelect",
      valueOptions: [...new Set(data.sessions.map((s) => s.room_name).filter(Boolean))],
      flex: 1
    });
    if (data.config.enabled_code) {
      columnArray.push({
        headerName: "Code",
        field: "code",
        flex: 1
      });
    }
    columnArray.push({
      headerName: "Type",
      field: "session_type_name",
      type: "singleSelect",
      valueOptions: [...new Set(data.sessions.map((s) => s.session_type_name).filter(Boolean))],
      flex: 1
    });
    columnArray.push({
      headerName: "Attendees",
      field: "attendee_count",
      flex: 1
    });
    columnArray.push({
      headerName: "Speakers",
      field: "speaker_count",
      flex: 1
    });
    columnArray.push({
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 200,
      flex: 1,
      getActions: (params) => [
        renderViewAction(params.row),
        renderEditAction(params.row),
        renderDeleteAction(params.row),
        renderCloneAction(params.row)
      ]
    });
    return columnArray;
  };

  const performClone = (session) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/sessions", `/${session.id}`, "/clone"),
      method: "POST"
    }).then((response) => {
      if (response.data.error === null) {
        refreshSessions.mutate();
        alertSuccess("Session successfully cloned");
      } else {
        alertError(response.data.error);
      }
    });
  };

  const performDelete = (session) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/sessions", `/${session.id}`),
      method: "DELETE"
    }).then((response) => {
      if (response.data.error === null) {
        refreshSessions.mutate();
        alertSuccess("Session deleted successfully");
      } else {
        alertError(response.data.error);
      }
    });
  };

  const renderViewAction = (session) => (
    <>
      <span
        className="cursor-pointer"
        onClick={() => {
          modalViewOpen(session.id);
        }}
      >
        View
      </span>
    </>
  );

  const renderEditAction = (session) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <Link className="cursor-pointer" to={`/session/${session.id}`}>
          Edit
        </Link>
      </>
    );
  };

  const renderDeleteAction = (session) => {
    if (!deleteEnabled()) {
      return <></>;
    }

    return (
      <>
        <div
          className="cursor-pointer"
          onClick={() => {
            confirm({
              title: "Confirm delete",
              description: "Are you sure you want to delete this session?"
            })
              .then(() => {
                performDelete(session);
              })
              .catch((err) => {
                alertError(err);
              });
          }}
        >
          Delete
        </div>
      </>
    );
  };

  const renderCloneAction = (session) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <div
          className="cursor-pointer"
          onClick={() => {
            confirm({
              title: "Confirm clone",
              description: "Are you sure you want to clone this session?"
            })
              .then(() => {
                performClone(session);
              })
              .catch((err) => {
                alertError(err);
              });
          }}
        >
          Clone
        </div>
      </>
    );
  };

  const modalAddOpen = () => {
    setModalAddVisible(true);
  };

  const modalAddClose = () => {
    setModalAddVisible(false);
  };

  const modalAddReset = () => {
    modalAddClose();
  };

  const modalViewOpen = (id) => {
    setViewSessionId(id);
    setModalViewVisible(true);
  };

  const modalViewReset = () => {
    setModalViewVisible(false);
    setViewSessionId(null);
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.sessions_edit) {
      return false;
    }
    return true;
  };

  const deleteEnabled = () => {
    if (user.role === "basic" && !user.permission.sessions_delete) {
      return false;
    }
    return true;
  };

  const renderTable = (filteredSessions, date) => (
    <EventSessionsTable
      columns={tableColumns()}
      date={date}
      key={date}
      items={filteredSessions}
      sortField="start_time"
      sortDirection="asc"
      title={format(date, "EEEE, MMMM do, y")}
    />
  );

  const renderTables = () => {
    if (!event.date_begin || !event.date_end) {
      return [];
    }

    // TODO: Get dates from the sessions themselves.
    // Using event start/end days means you can have sessions in the system
    // that don't appear on the Sessions page if their dates are outside the
    // currently set start/end dates (ie. edited after event creation)
    const dateRange = eachDayOfInterval({
      start: parseISO(event.date_begin),
      end: parseISO(event.date_end)
    });
    const tables = [];
    forEach(dateRange, (date) => {
      const dateString = format(date, "y-MM-dd");
      const filteredSessions = data.sessions.filter((s) => {
        // HACK: filter out sessions without date/time
        // sessions without date/time is not really supported yet,
        // and breaks the session table UI. Filter them out for the
        // time being until we properly support this use case.
        if (!s.date_and_time_local) {
          return false;
        }

        const sessDate = s.date_and_time_local.split("T")[0];
        return sessDate === dateString;
      });
      if (filteredSessions.length > 0) {
        tables.push(renderTable(filteredSessions, date));
      }
    });
    return tables;
  };

  const renderNoSessionsMessage = () => {
    if (data.sessions.length === 0) {
      return (
        <p>
          <em>No sessions created yet.</em>
        </p>
      );
    }
    return <></>;
  };

  const renderNoDatesMessage = () => {
    if (!event.date_begin || !event.date_end) {
      return (
        <p>
          <em>You must configure start and end dates in the event settings before you can create sessions.</em>
        </p>
      );
    }
    return <></>;
  };

  const renderBody = () => {
    if (isPending) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    if (!data) {
      return <div>No data.</div>;
    }

    return (
      <>
        <div>{editEnabled() ? renderCreateButton("Create Session", modalAddOpen, false) : ""}</div>
        <div className="sg-mgmt-event-sessions-wrapper">
          {renderTables()}
          {renderNoDatesMessage()}
          {renderNoSessionsMessage()}
        </div>
        <EventSessionsModalAdd
          config={data.config}
          modalVisible={modalAddVisible}
          resetModal={modalAddReset}
          tags={data.tags}
          updateFunc={refreshSessions}
          venues={data.venues}
        />
        <EventSessionModalView
          modalVisible={modalViewVisible}
          resetModal={modalViewReset}
          sessionId={viewSessionId}
          venues={data.venues}
        />
      </>
    );
  };

  return (
    <div>
      <PageHeader text="Session Management" />
      {renderBody()}
    </div>
  );
};

export default EventSessions;
