import React from "react";

import Modal from "react-modal";

import TemplateQuestionForm from "./TemplateQuestionForm";

const TemplateQuestionFormModal = (props) => {
  const { modalVisible, modalClose, id, callbackSuccess, callbackFailure } = props;
  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel="Web Page Template Question Form"
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={modalClose}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{id ? "Edit " : "Add "} Template Question</div>
        <div className="sg-mgmt-modal-content">
          {modalVisible ? (
            <TemplateQuestionForm
              key={`template_question_form_${id}`}
              id={id}
              cancel={modalClose}
              callbackSuccess={callbackSuccess}
              callbackFailure={callbackFailure}
              {...props}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </Modal>
  );
};

export default TemplateQuestionFormModal;
