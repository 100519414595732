import React from "react";

import Modal from "react-modal";

import { alertError, alertSuccess } from "@shared/Alerts";

import MetadataFieldForm from "./MetadataFieldForm";

const MetadataFieldModal = (props) => {
  const { field, modalVisible, resetModal, title, updateFunc, fieldType } = props;

  Modal.setAppElement("#root");

  const success = (response) => {
    alertSuccess("Field saved successfully");
    updateFunc(response.data.field);
    resetModal();
  };

  const failure = () => {
    alertError("Failed to save field");
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <MetadataFieldForm
            callbackFailure={failure}
            callbackSuccess={success}
            cancelButton={resetModal}
            field={field}
            fieldType={fieldType}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MetadataFieldModal;
