import React, { useContext, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

import { Logout as LogoutIcon, MenuBook as MenuBookIcon, ViewModule as ViewModuleIcon } from "@mui/icons-material";
import { Divider, ListItem, ListItemIcon, ListItemText, MenuItem, MenuList, Paper } from "@mui/material";

import EventUserContext from "@event/EventUserContext";

const UserMenu = (props) => {
  const [visible, setVisible] = useState(false);
  const wrapperRef = useRef(null);
  let { user } = useContext(EventUserContext);
  user = props.user || user;
  const menuHide = () => {
    setVisible(false);
  };

  const menuToggle = () => {
    setVisible(!visible);
  };

  useEffect(() => {
    const handleClick = (evt) => {
      if (wrapperRef.current && !wrapperRef.current.contains(evt.target)) {
        menuHide();
      }
    };

    window.addEventListener("mousedown", handleClick);

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const menuItems = () => {
    return (
      <Paper
        sx={{
          overflow: "hidden",
          position: "absolute",
          top: 40,
          right: 0,
          minWidth: 220,
          width: "240px",
          maxWidth: "100%",
          zIndex: 2
        }}
      >
        <MenuList>
          <ListItem>
            <div>
              <div className="font-['Roboto'] text-base font-normal leading-normal tracking-tight text-black text-opacity-90">
                {user.name_first} {user.name_last}
              </div>
              <div className="font-['Roboto'] text-xs font-normal leading-tight tracking-tight text-black text-opacity-60">
                {user.email}
              </div>
            </div>
          </ListItem>
          <Divider sx={{ margin: "8px 0" }} />

          <MenuItem component="a" href="/events">
            <ListItemIcon>
              <ViewModuleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Events Overview</ListItemText>
          </MenuItem>

          <Divider sx={{ margin: "8px 0" }} />

          <MenuItem component="a" href="/docs/api">
            <ListItemIcon>
              <MenuBookIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>API Documentation</ListItemText>
          </MenuItem>

          <Divider sx={{ margin: "8px 0" }} />

          <MenuItem component="a" href="/users/logout">
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Sign Out</ListItemText>
          </MenuItem>
        </MenuList>
      </Paper>
    );
  };

  const renderItems = () => {
    if (visible) {
      return menuItems();
    }
    return <></>;
  };

  const renderUserIcon = () => {
    if (user) {
      console.log(user);
      return (
        <div
          onClick={menuToggle}
          className="inline-flex h-[2rem] w-8 cursor-pointer flex-col items-center justify-center overflow-hidden rounded-full bg-stone-300"
        >
          <div className="absolute text-center font-['Roboto'] text-xl font-normal leading-tight tracking-tight text-white">
            {(user.name_first || " ")[0]}
            {(user.name_last || " ")[0]}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="sg-mgmt-user-menu-wrapper" ref={wrapperRef}>
      {renderUserIcon()}
      {renderItems()}
    </div>
  );
};

UserMenu.propTypes = {
  user: PropTypes.object
};

export default UserMenu;
