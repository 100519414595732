import React from "react";

import { SnackbarProvider } from "notistack";

import Footer from "@shared/Footer";
import { useEventMetadata } from "@shared/hooks/useEventMetadata";
import Loading from "@shared/Loading";
import MuiAlert from "@shared/MuiAlert";

import MainBanner from "./MainBanner";
import MainEventForm from "./MainEventForm";

const MainEventEditApp = (props) => {
  const { config, event, rootUrl, user } = props;
  const eventMetadata = useEventMetadata(event.id);

  if (eventMetadata.isLoading) {
    return <Loading />;
  }

  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={3500}
      Components={{
        mui: MuiAlert
      }}
    >
      <div className="sg-mgmt-container">
        <MainBanner config={config} rootUrl={rootUrl} user={user} />
        <div className="sg-mgmt-body-container container">
          <div className="row">
            <div className="col-xl-12">
              <div className="sg-mgmt-body">
                <MainEventForm {...props} eventMetdata={eventMetadata} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </SnackbarProvider>
  );
};

export default MainEventEditApp;
