import React from "react";

import ContentFrame from "@shared/ContentFrame";

import StaticFilesIndex from "./StaticFilesIndex";

const StaticFiles = () => {
  return (
    <ContentFrame>
      <StaticFilesIndex key={`static-files-index`} />
    </ContentFrame>
  );
};

export default StaticFiles;
