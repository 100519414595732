import React from "react";
import PropTypes from "prop-types";

import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import MuiSelectClearable from "@shared/MuiSelectClearable";
const WidgetFilterByTicket = (props) => {
  const { control, tickets } = props;
  return (
    <>
      <div className="mb-4 mt-4">
        <div className="mb-4 w-full">
          <FormControl fullWidth>
            <FormLabel>Filter by Ticket (optional)</FormLabel>
            <Controller
              name="widget_config[filters][ticket]"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <Select
                  {...field}
                  fullWidth
                  onChange={onChange}
                  value={value}
                  defaultValue=""
                  variant="outlined"
                  size="small"
                  MenuProps={{ disableScrollLock: true }}
                  endAdornment={(
                    <MuiSelectClearable value={value} onChange={onChange} />
                  )}
                >
                  {tickets.map((tk) => {
                    return (
                      <MenuItem key={`tk-${tk.gid}`} value={tk.gid}>
                        {`${tk.name}`}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            />
          </FormControl>
        </div>
      </div>
    </>
  );
};

WidgetFilterByTicket.propTypes = {
  control: PropTypes.object.isRequired,
  tickets: PropTypes.array.isRequired
};

export default WidgetFilterByTicket;
