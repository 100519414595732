import React from "react";

import { ContentCopy as ContentCopyIcon } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import Modal from "react-modal";

import { alertSuccess } from "@shared/Alerts";
import { renderCancelButton } from "@shared/FormUtils";

const EventApisTokenView = (props) => {
  const { modalVisible, resetModal, title, token } = props;

  Modal.setAppElement("#root");

  const copyToClipboard = () => {
    alertSuccess("Copied token value to clipboard");
    navigator.clipboard.writeText(window.location.toString());
  };

  const renderToken = () => (
    <div>
      <div>
        <span className="font-bold text-sg-orange">Token Value:</span> {token.value}{" "}
        <ContentCopyIcon className="cursor-pointer" onClick={copyToClipboard} fontSize="small" color="primary" />
      </div>
      <div>
        <span className="font-bold text-sg-orange">IP whitelist:</span> {token.whitelist.toString().replace(",", ", ")}
      </div>
      <div className="my-4">
        <div className="font-bold text-sg-orange">Enabled Permissions:</div>
        {Object.keys(token)
          .filter((key) => token[key] === true)
          .map((perm) => (
            <div key={perm}>{perm}</div>
          ))}
      </div>
    </div>
  );

  const renderActions = () => (
    <Stack direction="row" spacing={2}>
      {renderCancelButton("Close", resetModal)}
    </Stack>
  );

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          {renderToken()}
          {renderActions()}
        </div>
      </div>
    </Modal>
  );
};

export default EventApisTokenView;
