import React, { useContext } from "react";

import { Stack, Chip } from "@mui/material";
import { Formik, Form } from "formik";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import SelectField from "@shared/forms/SelectField";
import {
  renderCancelButton,
  renderDateField,
  renderSelectField,
  renderTextField,
  renderTextAreaField,
  renderIntegerField,
  renderButton,
  renderSubmitButton
} from "@shared/FormUtils";
import { useAddTicketType, useUpdateTicketType } from "@shared/hooks/useTickets";

const TicketingTicketTypeForm = (props) => {
  const { ticketType, ticketTags, cancel, callbackSuccess, callbackFailure } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const addTicketType = useAddTicketType(apiRoot, event.id);
  const updateTicketType = useUpdateTicketType(apiRoot, event.id);

  const isEdit = () => {
    return ticketType;
  };

  const title = isEdit() ? `Edit ${ticketType.name}` : "Create New Ticket Type";
  const buttonColor = isEdit() ? "primary" : "secondary";
  const buttonText = isEdit() ? "Save" : "Create";

  const ticketTypeInitialValues = () => {
    return {
      archived: ticketType?.archived || false,
      end_datetime: ticketType?.end_datetime || null,
      name: ticketType?.name || "",
      sort_order: ticketType?.sort_order || 0,
      start_datetime: ticketType?.start_datetime || null,
      total_count_type: ticketType?.total_count_type || "unlimited",
      total_count: ticketType?.total_count || 0,
      notes: ticketType?.notes || "",
      tags: ticketType?.tags || []
    };
  };

  const formValidation = (values) => {
    const errors = {};
    if (!values.ticket_type.name) {
      alert("You must enter a unique ticket name.");
      errors.ticket_type = errors.ticket_type || {};
      errors.ticket_type.name = "Required";
    }

    if (
      values.ticket_type.ticket_count_type == "limited" &&
      (isNaN(values.ticket_type.total_count) || values.ticket_type.total_count < 1)
    ) {
      alert("You must enter a total available ticket count.");
      errors.ticket_type = errors.ticket_type || {};
      errors.ticket_type.total_count = "Required";
    }
    console.log({
      errors
    });
    return errors;
  };

  const addTag = (gid, formikProps) => {
    const { values } = formikProps;
    // ignore duplicate tag
    if (values.ticket_type.tags.map((t) => t.gid).includes(gid)) {
      return;
    }

    // ignore missing tag
    if (!gid) {
      return;
    }

    const tag = ticketTags.find((t) => t.gid === gid);
    formikProps.setFieldValue("ticket_type[tags]", [tag].concat(values.ticket_type.tags));
  };

  const removeTag = (gid, formikProps) => {
    const { values } = formikProps;
    formikProps.setFieldValue(
      "ticket_type[tags]",
      values.ticket_type.tags.filter((t) => t.gid !== gid)
    );
  };

  const handleAddTagForm = (gid, formikProps) => {
    addTag(gid, formikProps);
  };

  const renderSelectedTags = (formikProps) => {
    const { values } = formikProps;
    return (
      <div className="mt-2 flex">
        {values.ticket_type.tags.map((tag) => (
          <Chip
            key={tag.id}
            className="mr-2"
            color="primary"
            label={tag.name}
            onDelete={() => removeTag(tag.gid, formikProps)}
          />
        ))}
      </div>
    );
  };

  const renderTagAddButton = (formikProps) => {
    const { values } = formikProps;
    const tag_add = values.tag_add;
    const selectedTag = tag_add ? tag_add : false;
    const disabled = !selectedTag || selectedTag === "Select option";

    return renderButton(
      "Add",
      () => {
        handleAddTagForm(tag_add, formikProps);
      },
      { disabled: disabled, color: "secondary" }
    );
  };

  const renderTagSelect = (formikProps) => {
    const { values } = formikProps;
    const filteredTags = ticketTags.filter((t) => !values.ticket_type.tags.map((tag) => tag.gid).includes(t.gid));
    const options = filteredTags.map((opt) => ({
      label: opt.name,
      value: opt.gid
    }));
    return (
      <Stack spacing={2} direction="row">
        <SelectField disableLabel={true} name={"tag_add"} options={options} />
        {renderTagAddButton(formikProps)}
      </Stack>
    );
  };

  const renderTags = (formikProps) => {
    return (
      <>
        {renderTagSelect(formikProps)}
        {renderSelectedTags(formikProps)}
      </>
    );
  };

  const renderFormFields = (formikProps) => {
    return (
      <div className="sg-mgmt-form-container">
        <div className="sg-mgmt-form-section">
          <div className="flex">
            <div className="mr-4 w-full">{renderTextField("Ticket Name", "ticket_type[name]", [], false, true)}</div>
          </div>

          <div className="flex">
            <div className="mr-4 w-full">
              {renderSelectField(
                "Ticket Count",
                "ticket_type[total_count_type]",
                [
                  { value: "unlimited", label: "Unlimited" },
                  { value: "limited", label: "Limited" }
                ],
                false,
                true
              )}
            </div>
          </div>
          {formikProps.values.ticket_type.total_count_type == "limited" ? (
            <div className="flex">
              <div className="mr-4 w-full">
                {renderIntegerField(
                  "Total Available Tickets",
                  "ticket_type[total_count]",
                  [],
                  0,
                  1,
                  10000000,
                  false,
                  true
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="flex">
            <div className="mr-4 w-1/2">{renderDateField("Start Date (optional)", "ticket_type[start_datetime]")}</div>
            <div className="mr-4 w-1/2">{renderDateField("End Date (optional)", "ticket_type[end_datetime]")}</div>
          </div>

          <div className="flex">
            <div className="mr-4 w-full">{renderTextAreaField("Description", "ticket_type[notes]")}</div>
          </div>

          <h2>Tags</h2>
          {renderTags(formikProps)}
        </div>
      </div>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          ticket_type: ticketTypeInitialValues()
        }}
        validate={formValidation}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={(values, { setSubmitting }) => {
          const callbacks = {
            onSuccess: (data) => {
              if (data.error === null) {
                alertSuccess("Ticket Type Saved");
                callbackSuccess();
              } else {
                alertError(`Unable to save ticket type: ${data.error}`);
                callbackFailure();
                setSubmitting(false);
              }
            },
            onError: (error) => {
              if (error?.response?.data?.error) {
                alertError(error.response?.data?.error);
              } else {
                alertHttpError(error);
              }
              setSubmitting(false);
            }
          };

          if (isEdit()) {
            delete values["tag_add"];
            updateTicketType.mutate({ id: ticketType.id, data: values }, callbacks);
          } else {
            addTicketType.mutate({ data: values }, callbacks);
          }
        }}
      >
        {(formikProps) => (
          <Form className="sg-mgmt-form" id="sg-mgmt-form-ticket-type">
            {renderFormFields(formikProps)}
            {renderTicketTypeGid()}
            {renderButtons(formikProps)}
          </Form>
        )}
      </Formik>
    );
  };

  const renderTicketTypeGid = () => {
    if (!ticketType) {
      return <></>;
    }

    return <div className="mb-1 mt-1">GID: {ticketType?.gid}</div>;
  };

  const renderButtons = (formikProps) => {
    const { isSubmitting } = formikProps;
    return (
      <Stack direction="row" spacing={2}>
        {renderSubmitButton(buttonText, isSubmitting, { color: buttonColor })}
        {renderCancelButton("Cancel", cancel, { color: buttonColor })}
      </Stack>
    );
  };

  return (
    <div>
      <div className={`sg-mgmt-modal-title ${isEdit() ? "sg-mgmt-modal-title-edit" : ""}`}>{title}</div>
      <div className="sg-mgmt-modal-content">
        <div>{renderForm()}</div>
      </div>
    </div>
  );
};

export default TicketingTicketTypeForm;
