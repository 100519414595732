import React from "react";

import clsx from "clsx";

const CheckFieldDisabled = (props) => {
  const { label, checked = true } = props;

  const checkMark = () => {
    return checked ? <div className="sg-mgmt-checkbox-disabled-checked" /> : <></>;
  };

  return (
    <label className="sg-mgmt-checkbox-container">
      {label}
      <span className={clsx("sg-mgmt-checkbox", "sg-mgmt-checkbox-disabled")}>{checkMark()}</span>
    </label>
  );
};

export default CheckFieldDisabled;
