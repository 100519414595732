import React from "react";

import { Outlet } from "react-router";

import ErrorFallback from "@shared/ErrorFallback";
import Footer from "@shared/Footer";
import { useAppsignal } from "@shared/useAppsignal";

import EventBanner from "./EventBanner";
import EventMenu from "./menu/EventMenu";

const Event = () => {
  const { appsignal, ErrorBoundary } = useAppsignal();

  return (
    <div className="sg-mgmt-container">
      <EventBanner />
      <div className="sg-mgmt-body-container">
        <div className="sg-mgmt-body">
          <EventMenu />
          <ErrorBoundary
            key={`error-boundary-event`}
            instance={appsignal}
            tags={{ tag: "Event" }}
            fallback={(_error) => <ErrorFallback />}
          >
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Event;
