import React from "react";

import ContentFrame from "@shared/ContentFrame";

import EventApisTokens from "./EventApisTokens";
import EventApisIntegrations from "./integrations/EventApisIntegrations";

const EventApis = () => {
  return (
    <ContentFrame>
      <EventApisTokens />
      <EventApisIntegrations />
    </ContentFrame>
  );
};

export default EventApis;
