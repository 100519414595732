import React, { useEffect, useState } from "react";

import { Stack } from "@mui/material";
import axios from "axios";
import { Link, useResolvedPath } from "react-router";
import urljoin from "url-join";

import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton, renderButton } from "@shared/FormUtils";
import GrowlModal from "@shared/GrowlModal";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";

import PeopleModal from "./PeopleModal";

const People = (props) => {
  const { rootUrl } = props;
  const [people, setPeople] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const url = useResolvedPath("").pathname;

  useEffect(() => {
    const fetchPeople = async () => {
      try {
        const result = await axios(urljoin(rootUrl, "/-/individuals"));
        setPeople(result.data.individuals);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchPeople();
  }, [rootUrl]);

  const deleteIndividual = (id) => {
    setPeople(people.filter((e) => e.id !== id));
  };

  const performDelete = (id) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(rootUrl, `/-/individuals/${id}`),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error === null) {
          deleteIndividual(id);
          alertSuccess("Individual deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const deleteConfirmMsg = (name) => (
    <>
      <p>Are you sure you want to delete {name}?</p>
      <p>WARNING: This will remove this Person&apos;s entire event history.</p>
      <p>This function should be reserved for clearing out test accounts or bad/duplicate records.</p>
      <p>This is NOT a soft delete, and deleted data will be unrecoverable.</p>
    </>
  );

  const columns = [
    {
      headerName: "First Name",
      field: "name_first",
      flex: 1
    },
    {
      headerName: "Last Name",
      field: "name_last",
      flex: 1
    },
    {
      headerName: "Email",
      field: "email",
      flex: 1
    },
    {
      headerName: "Company",
      field: "company",
      flex: 1
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      flex: 1,
      getActions: (rowData) => [
        <>
          <Link to={`${url}/individual/${rowData.row.id}`}>
            <span className="cursor-pointer">View/Edit</span>
          </Link>
        </>,
        <>
          <GrowlModal
            content={deleteConfirmMsg(`${rowData.row.name_first} ${rowData.row.name_last}`)}
            headerName={`Delete Person: ${rowData.row.name_first} ${rowData.row.name_last}`}
            trigger={<span className="cursor-pointer">Delete</span>}
            actions={[
              {
                label: "Cancel",
                close: true
              },
              {
                label: "Delete",
                close: true,
                func: () => {
                  performDelete(rowData.row.id);
                }
              }
            ]}
          />
        </>
      ]
    }
  ];

  const openAddModal = () => {
    setAddModalVisible(true);
  };

  const closeAddModal = () => {
    setAddModalVisible(false);
  };

  const resetAddModal = () => {
    closeAddModal();
  };

  const addPerson = (ind) => {
    setPeople([ind].concat(people));
  };

  const renderPeopleTable = () => {
    if (!fetched) {
      return <Loading />;
    }

    return (
      <GrowlTable columns={columns} items={people} sortColumn="name_last" sortDirection="asc" tableName="people" />
    );
  };

  const renderAddPersonButton = () => {
    return renderCreateButton("Add Person", openAddModal, false, {
      className: "my-0"
    });
  };

  const renderReportButton = () => {
    return <a href="/reporting/individuals">{renderButton("Download Report", () => {})}</a>;
  };

  return (
    <div>
      <div className="sg-mgmt-index-header">People</div>
      <Stack direction="row" spacing={2}>
        {renderAddPersonButton()}
        {renderReportButton()}
      </Stack>
      <div className="sg-mgmt-content sg-mgmt-content-full">{renderPeopleTable()}</div>
      <PeopleModal
        rootUrl={rootUrl}
        closeModal={closeAddModal}
        modalVisible={addModalVisible}
        resetModal={resetAddModal}
        updateFunc={addPerson}
      />
    </div>
  );
};

export default People;
