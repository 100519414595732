import React, { useContext, useEffect, useState } from "react";

import { Stack } from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import { Formik, Field, Form } from "formik";
import { Link } from "react-router";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";
import CheckFieldDisabled from "@shared/CheckFieldDisabled";
import { renderCheckField, renderCancelButton, renderSubmitButton } from "@shared/FormUtils";
import PageHeader from "@shared/PageHeader";

import EventSessionTags from "./tags/EventSessionTags";
import EventSessionTypes from "./types/EventSessionTypes";

const EventSessionsSettings = () => {
  const { apiRoot, webRoot } = useContext(EventContext).values;
  const [config, setConfig] = useState([]);
  const [tags, setTags] = useState([]);
  const [types, setTypes] = useState([]);

  const [fetched, setFetched] = useState(false);
  const formId = "sg-mgmt-event-session-settings-form";

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/sessions/settings"));
        setConfig(result.data.config);
        setTags(result.data.tags);
        setTypes(result.data.types);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchConfig();
  }, [apiRoot]);

  const renderDummyCheckField = (label, checked = true, formatClasses = []) => (
    <div className="sg-mgmt-form-row">
      <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
        <CheckFieldDisabled label={label} checked={checked} />
      </div>
    </div>
  );

  const renderFieldOptions = (field, formatClasses = []) => (
    <div className="sg-mgmt-form-row">
      <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
        <>
          <label>Enter one option per line:</label>
          <Field
            className="cms-category-form-input cms-category-form-input-text"
            component="textarea"
            name={`config[${field}]`}
          />
        </>
      </div>
    </div>
  );

  const renderOption = (fieldName, enabled) => {
    return enabled ? renderFieldOptions(fieldName, "sg-mgmt-form-input-options") : <></>;
  };

  const renderForm = () => (
    <Formik
      initialValues={{ config }}
      onSubmit={(values, { setSubmitting }) => {
        const formData = values;
        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

        // Clear type/track options if boolean is unselected
        if (!values.config.enabled_session_type) {
          formData.config.options_session_type = "";
        }
        if (!values.config.enabled_track) {
          formData.config.options_track = "";
        }

        // Remove type/track selection if no types or tracks were entered
        if (values.config.options_session_type === "") {
          formData.config.enabled_session_type = false;
        }
        if (values.config.options_track === "") {
          formData.config.enabled_track = false;
        }

        axios({
          url: urljoin(apiRoot, "/sessions/settings"),
          method: "PATCH",
          data: formData
        }).then((response) => {
          if (response.data.error === null) {
            window.location.replace(urljoin(webRoot, "/sessions"));
          } else {
            alertError(response.data.error);
            setSubmitting(false);
          }
        });
      }}
    >
      {({ values, isSubmitting }) => (
        <Form className="sg-mgmt-form" id={formId}>
          <div className="sg-mgmt-form-container">
            <h2>General Info</h2>
            {renderDummyCheckField("Session Name (required)", true)}
            {renderCheckField("Description", "config[enabled_description]")}
            {renderCheckField("Code", "config[enabled_code]")}
            {renderCheckField("Session Track", "config[enabled_track]")}
            {renderOption("options_track", values.config.enabled_track)}
            {renderDummyCheckField("Session Date and Time (required)", true)}
            {renderDummyCheckField("Speakers (required)", true)}
            {renderDummyCheckField("Video Session Upload (required)", true)}
          </div>
          <Stack direction="row" spacing={2}>
            {renderSubmitButton("Save", isSubmitting)}
            <Link to="/sessions">{renderCancelButton("Cancel", () => {})}</Link>
          </Stack>
        </Form>
      )}
    </Formik>
  );

  const renderConfig = () => {
    if (fetched) {
      return (
        <div>
          <p>Add or edit form fields that will display on the session entry module.</p>
          {renderForm()}
        </div>
      );
    }
    return <h2>Loading</h2>;
  };

  const renderSessionTypes = () => {
    if (fetched) {
      return <EventSessionTypes apiRoot={apiRoot} sessionTypes={types} setTypes={setTypes} />;
    }
    return <h2>Loading</h2>;
  };

  const renderTags = () => {
    if (fetched) {
      return <EventSessionTags apiRoot={apiRoot} tags={tags} setTags={setTags} />;
    }
    return <h2>Loading</h2>;
  };

  return (
    <div>
      <PageHeader text="Session Settings" />
      <div className="mb-12">
        <h2>Edit Session Settings</h2>
        {renderConfig()}
      </div>
      <div className="mb-12">
        <h2>Edit Session Types</h2>
        {renderSessionTypes()}
      </div>
      <div className="mb-12">
        <h2>Edit Session Tags</h2>
        {renderTags()}
      </div>
    </div>
  );
};

export default EventSessionsSettings;
