import React, { useContext } from "react";

import EventUserContext from "@event/EventUserContext";
import RouterTabbedPage from "@shared/RouterTabbedPage";
import { TabSettingsIcon } from "@shared/tabs/TabIcons";

const Ticketing = () => {
  const { user } = useContext(EventUserContext);

  const getTabs = () => {
    let tabs = [
      {
        label: "Tickets",
        path: "/ticketing/tickets"
      },
      {
        label: "Packages",
        path: "/ticketing/packages"
      },
      {
        label: "Ticket Requests",
        path: "/ticketing/requests"
      }
    ];
    if (user && (user.role != "basic" || user.permission.ticketing_edit)) {
      tabs.push({
        label: "Settings",
        path: "/ticketing/settings",
        icon: TabSettingsIcon
      });
    }

    return tabs;
  };
  return <RouterTabbedPage tabs={getTabs()} />;
};

export default Ticketing;
