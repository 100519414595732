import React from "react";

import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Outlet, useLocation } from "react-router";

import ContentFrame from "@shared/ContentFrame";
import Footer from "@shared/Footer";
import MaterialTheme from "@shared/MaterialTheme";
import Tabs from "@shared/tabs/Tabs";

import MainBanner from "./MainBanner";

const Main = (props) => {
  const { config, rootUrl, user } = props;
  const location = useLocation();

  const getActiveTab = () => {
    if (location.pathname.includes("/people")) {
      return "people";
    }
    return "events";
  };

  return (
    <ThemeProvider theme={MaterialTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="sg-mgmt-container">
          <MainBanner config={config} rootUrl={rootUrl} user={user} />
          <Tabs
            tabs={[
              { id: "events", name: "Events", href: "/events" },
              { id: "people", name: "People", href: "/people" }
            ]}
            classNames={{
              outerContainer: "pr-[24px] pl-[24px]"
            }}
            activeTabId={getActiveTab()}
          />
          <ContentFrame className="p-[4.5rem]">
            <Outlet />
          </ContentFrame>
          <Footer />
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default Main;
