import React, { useState } from "react";

import { Stack } from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import { Formik, Form } from "formik";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import urljoin from "url-join";

import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderRichTextAreaField, renderTextField, renderSubmitButton, renderCancelButton } from "@shared/FormUtils";

import PeopleBlankIndividual from "./PeopleBlankIndividual";

const PeopleModal = (props) => {
  const { individual = PeopleBlankIndividual, modalVisible, resetModal, rootUrl, updateFunc } = props;
  const [photo, setPhoto] = useState(null);
  const [photoDropped, setPhotoDropped] = useState(false);
  const [previewPhotoUrl, setPreviewPhotoUrl] = useState(null);
  const dropzoneAccept = "image/png, image/jpg, image/jpeg, image/pjpeg";

  Modal.setAppElement("#root");

  const onDrop = (acceptedFiles) => {
    const reader = new FileReader();
    if (typeof window.FileReader !== "undefined") {
      reader.onload = (e) => {
        setPhoto(acceptedFiles[0]);
        setPhotoDropped(true);
        setPreviewPhotoUrl(e.target.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      setPhoto(acceptedFiles[0]);
      setPhotoDropped(true);
      setPreviewPhotoUrl(null);
    }
  };

  const dropzoneFilename = () => {
    if (photoDropped) {
      return (
        <>
          File attached:
          <br />
          {photo.name}
        </>
      );
    }
    return <>Upload Photo</>;
  };

  const renderPhoto = () => {
    if (previewPhotoUrl) {
      return (
        <img
          className="sg-mgmt-form-speaker-photo-container cursor-pointer"
          src={previewPhotoUrl}
          alt="Event Participant"
        />
      );
    }
    return (
      <div className="sg-mgmt-form-input-dropzone-tile-prompt">
        Drag and Drop
        <br />
        or Click to Choose File
      </div>
    );
  };

  const renderPhotoField = () => {
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Photo</label>
        <Dropzone accept={dropzoneAccept} onDrop={onDrop} name="event_participant[override_photo]">
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <>
                <div
                  {...getRootProps()}
                  className={clsx("dropzone", "sg-mgmt-form-input-dropzone-tile", {
                    "dropzone--isActive": isDragActive
                  })}
                >
                  <input {...getInputProps()} />
                  {renderPhoto()}
                </div>
                <div className="sg-mgmt-participant-photo-filename cursor-pointer">{dropzoneFilename()}</div>
              </>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  const config = (() => {
    if (individual && individual.id) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-attendee-edit",
        formUrl: urljoin(rootUrl, "/-/individuals/", `/${individual.id}`),
        method: "PATCH",
        title: "Edit Person"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-attendee-add",
      formUrl: urljoin(rootUrl, "/-/individuals"),
      method: "POST",
      title: "Add Person"
    };
  })();

  const formInitialValues = () => {
    if (individual) {
      return {
        name_last: individual.name_last || "",
        name_first: individual.name_first || "",
        job_title: individual.job_title || "",
        company: individual.company || "",
        email: individual.email || "",
        work_phone: individual.work_phone || "",
        work_address_street_1: individual.work_address_street_1 || "",
        work_address_street_2: individual.work_address_street_2 || "",
        work_address_city: individual.work_address_city || "",
        work_address_state: individual.work_address_state || "",
        work_address_postcode: individual.work_address_postcode || "",
        bio: individual.bio || ""
      };
    }
    return PeopleBlankIndividual;
  };

  const renderAddEditIndividualModal = () => {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{config.title}</div>
        <div className="sg-mgmt-modal-content">
          <Formik
            initialValues={{
              individual: formInitialValues()
            }}
            onSubmit={(values, { setSubmitting }) => {
              const form = document.getElementById(config.formId);
              const formData = new FormData(form);
              const token = document.querySelector("[name=csrf-token]").content;
              axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

              formData.set("individual[bio]", values.individual.bio);

              if (photo) {
                formData.set("individual[photo]", photo);
              }

              axios({
                url: config.formUrl,
                method: config.method,
                data: formData
              })
                .then((response) => {
                  if (response.data.error === null) {
                    updateFunc(response.data.individual);
                    resetModal();
                    alertSuccess(`Person ${config.alert} successfully`);
                  } else {
                    alertError(response.data.error);
                    setSubmitting(false);
                  }
                })
                .catch((error) => {
                  alertHttpError(error);
                });
            }}
          >
            {({ isSubmitting }) => (
              <Form className="sg-mgmt-form" id={config.formId}>
                <div className="sg-mgmt-form-container">
                  <div className="sg-mgmt-form-row flex">
                    <div className="mr-2 w-1/2">{renderTextField("First Name", "individual[name_first]")}</div>
                    <div className="w-1/2">{renderTextField("Last Name", "individual[name_last]")}</div>
                  </div>
                  <div className="sg-mgmt-form-row">{renderTextField("Company", "individual[company]")}</div>
                  <div className="sg-mgmt-form-row">{renderTextField("Job Title", "individual[job_title]")}</div>
                  <div className="sg-mgmt-form-row">{renderTextField("Email Address", "individual[email]")}</div>
                  <div className="sg-mgmt-form-row">
                    {renderTextField("Work Phone Number", "individual[work_phone]")}
                  </div>
                  <div className="sg-mgmt-form-row">
                    {renderTextField("Address (line 1)", "individual[work_address_street_1]")}
                  </div>
                  <div className="sg-mgmt-form-row">
                    {renderTextField("Address (line 2)", "individual[work_address_street_2]")}
                  </div>
                  <div className="sg-mgmt-form-row">
                    {renderTextField("City", "address_city")}
                    {renderTextField("State/Province", "individual[work_address_state]", [
                      "sg-mgmt-form-input-container-state pl-1"
                    ])}
                    {renderTextField("ZIP/Postal Code", "individual[work_address_postcode]", [
                      "sg-mgmt-form-input-container-postcode pl-1"
                    ])}
                  </div>
                  <div className="sg-mgmt-form-row">{renderRichTextAreaField("Bio", "individual[bio]")}</div>
                  <div className="sg-mgmt-form-speaker-container-photo">{renderPhotoField()}</div>
                </div>
                <Stack direction="row" spacing={2}>
                  {renderSubmitButton("Save", isSubmitting)}
                  {renderCancelButton("Cancel", resetModal)}
                </Stack>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Add Attendee"
    >
      {renderAddEditIndividualModal()}
    </Modal>
  );
};

export default PeopleModal;
