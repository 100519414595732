import React from "react";

import { useNavigate, useParams } from "react-router";

import ContentFrame from "@shared/ContentFrame";

import WebPageForm from "./forms/WebPageForm";

const getUrlParameter = (name) => {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  let results = regex.exec(window.location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
};

const WebPagesEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const templateId = getUrlParameter("contents_web_page_template_id");

  return (
    <ContentFrame>
      <WebPageForm
        webPageId={id != "new" ? id : null}
        cancel={() => navigate(`/content/web/pages`)}
        templateId={id == "new" && templateId ? templateId : null}
      />
    </ContentFrame>
  );
};

export default WebPagesEdit;
