import React from "react";

import MainIndex from "@main/index/MainIndex";
import Main from "@main/Main";
import MainEventForm from "@main/MainEventForm";
import People from "@people/People";
import PeopleApp from "@people/PeopleApp";
import PeopleIndividual from "@people/PeopleIndividual";

export const mainRoutes = (rootUrl, mainProps) => [
  {
    path: "/",
    element: <Main {...mainProps} />,
    children: [
      {
        path: "/events/new",
        element: <MainEventForm rootUrl={rootUrl} />
      },
      {
        path: "/events",
        element: <MainIndex rootUrl={rootUrl} />
      },
      {
        path: "/people",
        element: <PeopleApp rootUrl={rootUrl} />,
        children: [
          {
            index: true,
            element: <People rootUrl={rootUrl} />
          },
          {
            path: "individual/:individualId",
            element: <PeopleIndividual rootUrl={rootUrl} />
          }
        ]
      }
    ]
  }
];
