import React, { useContext } from "react";

import EventUserContext from "@event/EventUserContext";
import RouterTabbedPage from "@shared/RouterTabbedPage";
import { TabSettingsIcon } from "@shared/tabs/TabIcons";

const Sessions = () => {
  const { user } = useContext(EventUserContext);

  const getTabs = () => {
    let tabs = [
      { label: "Sessions", path: "/sessions" },
      { label: "Venues", path: "/sessions/venues" }
    ];

    if (user && (user.role != "basic" || user.permission.sessions_edit)) {
      tabs.push({ label: "Settings", path: "/sessions/settings", icon: TabSettingsIcon });
    }

    return tabs;
  };

  return <RouterTabbedPage tabs={getTabs()} />;
};

export default Sessions;
