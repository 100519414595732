import React, { useContext, useEffect, useState } from "react";

import Chip from "@mui/material/Chip";
import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";

import TicketingTicketTagsAdd from "./TicketingTicketTagsAdd";

const TicketingTicketTags = () => {
  const { apiRoot } = useContext(EventContext).values;
  const [tags, setTags] = useState([]);
  const [fetched, setFetched] = useState(false);
  const confirm = useConfirm();

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "ticketing/ticket_tags"));
        setTags(result.data.tags);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchTags();
  }, [apiRoot]);

  const addTag = (tag) => {
    setTags([tag].concat(tags));
  };

  const attemptDelete = (tagId) => {
    confirm({
      title: "Confirm removal",
      description: "Are you sure you want to remove this ticket tag? This cannot be undone."
    })
      .then(() => {
        deleteTag(tagId);
      })
      .catch((err) => {
        alertError(err);
      });
  };

  const deleteTag = (tagId) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      url: urljoin(apiRoot, "ticketing/ticket_tags", `/${tagId}`),
      method: "DELETE",
      data: {}
    }).then((response) => {
      if (response.data.error === null) {
        setTags(tags.filter((t) => t.id !== tagId));
        alertSuccess("Tag deleted");
      } else {
        alertError(response.data.error);
      }
    });
  };

  const renderTags = () => {
    if (fetched) {
      return (
        <div>
          <div className="mr-2 text-black">Ticket Tags:</div>
          <div className="my-2">
            {tags.map((tag) => (
              <Chip
                key={tag.id}
                className="mr-2"
                color="primary"
                label={tag.name}
                onDelete={() => attemptDelete(tag.id)}
              />
            ))}
          </div>
        </div>
      );
    }
    return <h2>Loading</h2>;
  };

  return (
    <div>
      <h2 className="font-normal">Manage Tags</h2>
      <div className="mb-4 mt-2">
        Tags allow you to split up smaller groups that may overlap. A ticket can have multiple or no tags. Examples: Tag
        by region (EMEA, AMER, APJC, etc.)
      </div>
      <TicketingTicketTagsAdd update={addTag} />
      {renderTags()}
    </div>
  );
};

export default TicketingTicketTags;
