import React from "react";

import { useNavigate, useParams } from "react-router";

import ContentFrame from "@shared/ContentFrame";

import TemplateForm from "./TemplateForm";

const TemplateEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <ContentFrame>
      <TemplateForm webPageTemplateId={id != "new" ? id : null} cancel={() => navigate(`/content/web/templates`)} />
    </ContentFrame>
  );
};

export default TemplateEdit;
