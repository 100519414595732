import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import { useNavigate } from "react-router";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { formatTime } from "@shared/TimeUtils";

import FeaturesFormModal from "./FeaturesFormModal";

const FeaturesOverview = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [features, setFeatures] = useState([]);
  const [fetched, setFetched] = useState(false);

  const [featuresModalOpen, setFeaturesModalOpen] = useState(false);
  const [featuresModalTarget, setFeaturesModalTarget] = useState(null);
  const navigate = useNavigate();

  const columns = [
    {
      headerName: "Feature Name",
      field: "name",
      flex: 1
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1
    },
    {
      headerName: "Updated At",
      field: "updated_at",
      renderCell: (params) => formatTime(params.value, event.time_zone),
      flex: 1
    },
    {
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: 180,
      getActions: (params) => [
        renderViewAction(params.row),
        renderEditAction(params.row),
        renderDeleteAction(params.row)
      ]
    }
  ];

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "feature_flags/features"));
        setFeatures(result.data["features"]);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchForms();
  }, [apiRoot]);

  const deleteFeature = (feature) => {
    setFeatures(features.filter((f) => f.id != feature.id));
  };

  const renderViewAction = (item) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            viewFeature(item);
          }}
        >
          View
        </span>
      </>
    );
  };

  const renderEditAction = (item) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            openEditFeaturesModal(item);
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const renderDeleteAction = (item) => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            performDelete(item);
          }}
        >
          Delete
        </span>
      </>
    );
  };

  const performDelete = (feature) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/feature_flags/features/", feature.id),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error === null) {
          deleteFeature(feature);
          alertSuccess("Feature deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const renderFeaturesTable = () => {
    if (fetched) {
      return (
        <GrowlTable
          columns={columns}
          items={features}
          sortColumn="name"
          sortDirection="asc"
          tableName={`${event.slug}-features`}
        />
      );
    }
    return <Loading />;
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.feature_flag_edit) {
      return false;
    }
    return true;
  };

  const renderNewFeatureButton = () => {
    return renderCreateButton("Create New Feature", openNewFeaturesModal);
  };

  const renderFeaturesModal = () => {
    return (
      <FeaturesFormModal
        featureId={featuresModalTarget}
        modalVisible={featuresModalOpen}
        resetModal={closeFeaturesModal}
        callbackFailure={() => {}}
        callbackSuccess={(response) => {
          updateFeatures(response.data.feature);
          closeFeaturesModal();
        }}
      />
    );
  };

  const openNewFeaturesModal = () => {
    setFeaturesModalTarget(null);
    setFeaturesModalOpen(true);
  };

  const openEditFeaturesModal = (item) => {
    setFeaturesModalTarget(item.id);
    setFeaturesModalOpen(true);
  };

  const updateFeatures = (feature) => {
    setFeatures(features.filter((x) => x.id != feature.id).concat([feature]));
  };

  const closeFeaturesModal = () => {
    setFeaturesModalOpen(false);
    setFeaturesModalTarget(null);
  };

  const viewFeature = (feature) => {
    //TODO go to edit page
    navigate(`/feature_flags/features/${feature.id}`);
  };

  return (
    <div>
      <PageHeader text="Features" />
      {renderNewFeatureButton()}
      <br />
      <br />
      {renderFeaturesTable()}
      {renderFeaturesModal()}
    </div>
  );
};

export default FeaturesOverview;
