import React from "react";

const MetadataDataTypeHelperContent = ({}) => {
  return (
    <div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">Text</div>
        General use, the data will be stored as a text field. Not restrictive of what information is stored. If unsure,
        select Text.
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">Dropdown</div>
        The data will be stored as one of the options given and must be in the specified list. If the option is not in
        the list, it will not save.
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">Checkboxes</div>
        Like dropdown, but you can select more than one.
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">Dates</div>
        Can be used to store dates in the form of a date picker.
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">Registration Flag</div>
        Allows us to pull all the fields that are flagged for registration.
      </div>
    </div>
  );
};

export default MetadataDataTypeHelperContent;
