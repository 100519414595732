import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

import { Stack } from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import { Formik, Form } from "formik";
import Dropzone from "react-dropzone";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";
import { renderSubmitButton } from "@shared/FormUtils";

const CommunicationsEmailMailingListImportForm = (props) => {
  const { apiRoot } = useContext(EventContext).values;
  const { callbackSuccess, listId } = props;
  const [sheet, setSheet] = useState({ id: null });
  const [sheetDropped, setSheetDropped] = useState(false);
  const dropzoneAccept = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  const formConfig = {
    alert: "added",
    formId: "sg-mgmt-form-list-import",
    formUrl: urljoin(apiRoot, `/communications/lists/${listId}/import`),
    method: "POST",
    title: "Import Contacts"
  };

  const formInitialValues = () => {
    return {
      sheet: "",
      csv_data: ""
    };
  };

  const onDrop = (acceptedFiles) => {
    const reader = new FileReader();
    if (typeof window.FileReader !== "undefined") {
      reader.onload = (_e) => {
        setSheet(acceptedFiles[0]);
        setSheetDropped(true);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      setSheet(acceptedFiles[0]);
      setSheetDropped(true);
    }
  };

  const renderSheet = () => {
    if (sheetDropped) {
      return (
        <div className="sg-mgmt-form-input-import-tile-prompt">
          Sheet Attached
          <i className="far fa-file-excel sg-mgmt-form-input-import-icon mx-auto my-4" />
          {sheet.name}
        </div>
      );
    }
    return (
      <div className="sg-mgmt-form-input-import-tile-prompt">
        Drag and Drop Spreadsheet
        <i className="far fa-file-excel sg-mgmt-form-input-import-icon mx-auto my-4" />
        or Click to Choose File
      </div>
    );
  };

  const renderSheetField = () => {
    return (
      <div>
        <Dropzone accept={dropzoneAccept} onDrop={onDrop} name="import[sheet]">
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <>
                <div
                  {...getRootProps()}
                  className={clsx("dropzone", "sg-mgmt-form-input-import-tile", {
                    "dropzone--isActive": isDragActive
                  })}
                >
                  <input {...getInputProps()} />
                  {renderSheet()}
                </div>
              </>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  const renderMailingListImportForm = () => {
    return (
      <Formik
        initialValues={{
          import: formInitialValues()
        }}
        onSubmit={({ setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

          if (sheet) {
            formData.set("import[sheet]", sheet);
          }

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          })
            .then((response) => {
              if (response.data.error == null) {
                callbackSuccess(response.data.sheet);
              } else {
                // callbackFailure(response);
                setSubmitting(false);
                alertError(response.data.error);
              }
            })
            .catch((error) => {
              alertHttpError(error);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-section">
                <h2>Import Contacts</h2>
                <p>Load contacts from an Excel spreadsheet</p>
                <div className="sg-mgmt-form-row">
                  <div className="sg-mgmt-form-row-column">{renderSheetField()}</div>
                </div>
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.title, isSubmitting || !sheetDropped)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <div>{renderMailingListImportForm()}</div>;
};

CommunicationsEmailMailingListImportForm.defaultProps = {
  callbackSuccess: () => {
    alertError("not implemented");
  }
};

CommunicationsEmailMailingListImportForm.propTypes = {
  callbackSuccess: PropTypes.func,
  listId: PropTypes.number.isRequired
};

export default CommunicationsEmailMailingListImportForm;
