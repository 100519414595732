import React from "react";

import { useParams } from "react-router";

import ContentFrame from "@shared/ContentFrame";

import FormsFormsIndex from "./forms/FormsFormsIndex";

const Forms = () => {
  const { form_type } = useParams();
  return (
    <ContentFrame>
      <FormsFormsIndex key={`forms-${form_type}`} form_type={form_type} />
    </ContentFrame>
  );
};

export default Forms;
